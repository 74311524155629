// External Dependencies
import Card from '@material-ui/core/Card';
import React from 'react';
import styled from 'styled-components';

const RebateListItem = ({ title, discountImage, isSelected, onSelect }) => {
  return (
    <RebateItemCard elevation={isSelected ? 5 : 3} onClick={onSelect} isSelected={isSelected}>
      <img alt="" src={discountImage} width="100%" height="70%" />
      <span>{title}</span>
    </RebateItemCard>
  );
};

const RebateSingleItem = ({ title, discountImage }) => (
  <RebateSingleItemCard elevation={1} style={{ gap: 10 }}>
    <img alt="" src={discountImage} style={{
      objectFit: 'contain', height: '12rem', maxWidth: '50%', padding: '0.5rem 1rem'
    }} />
    <span className="rebate-title" style={{}
    } > {title}</span>
  </RebateSingleItemCard >
);

/**
 * Displays a horizontal list of rebate offers. If only one rebate exist it takes up the entire space.
 * @param {function(string): boolean} isSelected - Determines selection status and updates styles in response.
 * @param {function(string): void} onSelect - Notifies parent that the user has clicked on a rebate.
 * @param {Object[]} onSelect - The list of all rebate offers to display.
 */
const RebateList = ({ onSelect = (id) => { }, rebateData, selectedRebates }) => {
  if (rebateData.length === 1) {
    return (
      <RebateSingleItem
        {...rebateData[0]}
        isSelected={selectedRebates?.includes(rebateData[0].title)}
      />
    );
  }

  return (
    <RebateListArea variant="outlined">
      {rebateData.map(({ id, title, ...data }) => (
        // @ts-ignore
        <RebateListItem
          {...data}
          title={title}
          key={title}
          isSelected={selectedRebates.includes(title)}
          onSelect={() => onSelect(title)}
        />
      ))}
    </RebateListArea>
  );
};

// Styled Components
const RebateListArea = styled(Card)`
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  height: 17em;
  overflow: scroll;
  padding: 8px 16px;
  &.MuiCard-root {
    overflow: scroll;
    width: 100%;
  }
`;

const RebateItemCard = styled<any>(({ isSelected, ...props }) => <Card {...props} />)`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  margin-right: 1em;
  width: 12em;
  cursor: pointer;
  &.MuiCard-root {
    box-sizing: border-box;
    border: ${({ isSelected }) => (isSelected ? 'solid 4px rgba(138,207,198,1)' : 'none')};
  }
`;

const RebateSingleItemCard = styled(Card)`
  color: #333;
  display: flex;
  flex-direction: row;
  font-family: 'Raleway';
  font-size: 1.5em;
  font-weight: 2em;
  font-weight: 600;
  cursor: default;
`;

export default RebateList;
