// External Dependencies
import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Route,
  Redirect,
} from "react-router-dom";

// Internal Dependencies
import { SessionContext } from 'session/SessionContext';

const AdminRoute = ({ children, ...props }) => {
  const { user, isGetting } = React.useContext(SessionContext);

  const loadingComponent = (
    <Box display="flex" >
      <Box m="auto">
        <CircularProgress />
      </Box>
    </Box>
  );

  return (
    <Route
      {...props}
      render={({ location }) =>
        isGetting ? loadingComponent
        : user?.isAdmin ? children
        : <Redirect to={{ pathname: "/", state: { from: location } }} />
      }
    />
  );
};

export default AdminRoute;
