import { Box, Button, Card, FormLabel, Switch, TextField } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import FileUpload from './FileUpload';
import SurveyQuestionForm from './SurveyQuestionForm';

const RebateForm = (props) => {
  const {
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    deleteRebate,
    rebateId,
    result,
    addSurveyQuestion
  } = props;
  return (
    <Card style={{ padding: 10, marginTop: 10, background: 'transparent' }}>
      <div>
        <div style={{ textAlign: 'center' }}>
          <DeleteOutline
            color="error"
            style={{ cursor: 'pointer' }}
            onClick={() => deleteRebate(rebateId)}
          />
        </div>
        <div>
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            placeholder="Enter name*"
            id={`rebates[${rebateId}].name`}
            label="Rebate Name"
            value={result.name}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.rebates?.[rebateId]?.name ? errors.rebates?.[rebateId]?.name : ''}
            error={touched.rebates?.[rebateId]?.name && Boolean(errors.rebates?.[rebateId]?.name)}
            required
          ></TextField>
        </div>
        <div>
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            placeholder="Enter title*"
            id={`rebates[${rebateId}].title`}
            label="Rebate Title"
            value={result.title}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.rebates?.[rebateId]?.title ? errors.rebates?.[rebateId]?.title : ''}
            error={touched.rebates?.[rebateId]?.title && Boolean(errors.rebates?.[rebateId]?.title)}
            required
          ></TextField>
        </div>
        <div>
          <TextField
            fullWidth
            type="number"
            margin="dense"
            variant="outlined"
            placeholder="Enter discount amount"
            id={`rebates[${rebateId}].discount`}
            label="Discount"
            value={result.discount}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              touched.rebates?.[rebateId]?.discount ? errors.rebates?.[rebateId]?.discount : ''
            }
            error={
              touched.rebates?.[rebateId]?.discount && Boolean(errors.rebates?.[rebateId]?.discount)
            }
            required
          ></TextField>
        </div>
        <Box sx={{ marginTop: 10 }}>
          <FileUpload
            setFieldValue={setFieldValue}
            id={`rebates[${rebateId}].discountImage`}
            name={result?.discountImage && result?.discountImage.split('?')[0].split('%2F')[1]}
            text="Upload Discount Image"
            type="rebate"
          />
          {errors?.rebates?.[rebateId]?.discountImage ? (
            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required MuiFormHelperText-marginDense">
              {' '}
              {errors.rebates[rebateId].discountImage}
            </p>
          ) : (
            ''
          )}
          {/* {values.rebates?.[rebateId]?.discountImage ? (
        <img
          alt="Logo"
          src={URL.createObjectURL(values.rebates[rebateId].discountImage)}
        />
      ) : (
        ''
      )} */}
        </Box>
        <div>
          <TextField
            multiline
            margin="dense"
            variant="outlined"
            rows={5}
            style={{ width: '100%' }}
            placeholder="Disclaimer*"
            id={`rebates[${rebateId}].disclaimer`}
            label="Disclaimer"
            value={result.disclaimer}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              touched.rebates?.[rebateId]?.disclaimer ? errors.rebates?.[rebateId]?.disclaimer : ''
            }
            error={
              touched.rebates?.[rebateId]?.disclaimer &&
              Boolean(errors.rebates?.[rebateId]?.disclaimer)
            }
            required
          />
          {/* <textarea rows={2} cols={40} /> */}
        </div>
        <div>
          <FormLabel>Survey</FormLabel>
          <Switch
            checked={result.hasSurvey}
            onChange={(e) => {
              setFieldValue(`rebates[${rebateId}].hasSurvey`, e.target.checked);
              // hasSurvey(rebateId);
            }}
          />
        </div>
        {result.hasSurvey && (
          <div>
            <Button variant="outlined" color="primary" onClick={() => addSurveyQuestion(rebateId)}>
              Add Questions
            </Button>
          </div>
        )}
        <div>
          {result.surveyQuestions.map((surveyQuestion, questionId) => {
            return (
              <SurveyQuestionForm
                {...props}
                questionId={questionId}
                surveyQuestion={surveyQuestion}
              />
            );
          })}
        </div>
      </div>
    </Card>
  );
};

export default RebateForm;
