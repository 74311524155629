// External Dependencies
import React from 'react';

const defaultAppContext = {
  appBar: {
    dialogType: '',
    hideModal: () => {},
    isOpen: false,
    showLoginModal: () => {},
    showProfileModal: () => {},
    showSignUpModal: () => {},
    showForgotPasswordModal: () => {},
  }
}; 

export const AppContext = React.createContext(defaultAppContext);
