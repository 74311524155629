import { Dialog, DialogTitle, DialogContent, Button, CircularProgress } from '@material-ui/core';
import React, { useCallback, useContext, useState } from 'react';
import { SessionContext } from 'session/SessionContext';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { toast } from 'react-toastify';

// Internal Dependencies
import {
  deleteEmailList,
  postEmailList,
  deleteTextList,
  postTextList,
  createOrUpdateUser
} from 'shared/api';
import { FlexRow } from 'shared/styled-components';
import { validateEmail, validatePhone } from 'shared/validation';
import { GroupHeader } from './profile-styled-components';
import { sendMessage } from 'utils/northText';
import SafeButton from 'shared/SafeButton';

const Notifications = ({ brandName = '' }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { user, userId, reloadUser } = useContext(SessionContext);
  const { email, isPromotionsEmailChecked, isPromotionsTextChecked, phoneNumber } = user;

  const [values, setValues] = useState({ isPromotionsEmailChecked, isPromotionsTextChecked });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.id]: e.target.checked }));
  };

  const handleSubmit = async (e) => {
    try {
      setIsSubmitting(true);
      // Phone number is validated before adding phonenumber to TextList collection.
      if (validatePhone(phoneNumber)) {
        if (values.isPromotionsTextChecked) {
          await postTextList(phoneNumber, brandName || 'Calitopia');
          // Confirmation Text is only sent  if initially isPromotionsTextChecked is false and check at the moment
          if (!user.isPromotionsTextChecked) {
            await sendMessage(phoneNumber);
          }
        }
        if (!values.isPromotionsTextChecked) {
          await deleteTextList(phoneNumber, brandName || 'Calitopia');
        }
      }

      // Email is validated before adding email to EmailList collection.
      if (validateEmail(email)) {
        if (values.isPromotionsEmailChecked) {
          await postEmailList(email, 'Calitopia');
        }

        if (!values.isPromotionsEmailChecked) {
          await deleteEmailList(email, 'Calitopia');
        }
      }
      await createOrUpdateUser(userId, { ...user, ...values });
      await reloadUser();
      setIsEditMode(false);
      setIsSubmitting(false);
      toast.success('Notifications Changed Successfully',{position: "top-right"});
    } catch (err) {

    }
  };

  const promotionsLabel =
    "I'd like to receive cannabis related promotions from Calitopia" +
    (brandName ? ` and ${brandName} ` : ' ');

  return (
    <React.Fragment>
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <GroupHeader style={{ alignSelf: 'center' }}>Notifications</GroupHeader>
        <Button onClick={() => setIsEditMode(!isEditMode)} size="small" variant="outlined">
          {isEditMode ? 'Cancel' : 'Edit'}
        </Button>
      </FlexRow>
      <FormGroup style={{ padding: '10px 0px' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.isPromotionsEmailChecked}
              id="isPromotionsEmailChecked"
              onChange={handleChange}
            />
          }
          label={promotionsLabel + 'via email'}
          style={isEditMode ? { cursor: 'pointer' } : { pointerEvents: 'none' }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={values.isPromotionsTextChecked}
              id="isPromotionsTextChecked"
              onChange={handleChange}
            />
          }
          label={promotionsLabel + 'via text'}
          style={isEditMode ? { cursor: 'pointer' } : { pointerEvents: 'none' }}
        />
      </FormGroup>
      {isEditMode && (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <SafeButton
            style={{ marginTop: 10, alignSelf: 'center' }}
            onClick={handleSubmit}
            isSubmitting={isSubmitting}
            variant="contained"
          >
            Save Changes
          </SafeButton>
        </div>
      )}
    </React.Fragment>
  );
};

const UserPermissionDialog = (dialogProps) => {
  return (
    <Dialog {...dialogProps}>
      <DialogTitle style={{ padding: '10px 25px' }}>Permissions</DialogTitle>
      <DialogContent>
        <Notifications />
      </DialogContent>
    </Dialog>
  );
};

export default UserPermissionDialog;
