// External Dependencies
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

// Internal Dependencies
import {ErrorText, FormGroupHeader} from 'shared/styled-components';
import {RegistrationForm, validateAccountForm} from 'shared/validation';
import {createNewUser, createOrUpdateUser, postEmailList, postTextList} from 'shared/api';
import AgreementFormGroup from 'shared/forms/AgreementFormGroup';
import MobileOtpFormGroup from 'shared/forms/MobileOtpFormGroup';
import NameFormGroup from 'shared/forms/NameFormGroup';
import PaymentFormGroup from 'shared/forms/PaymentFormGroup';
import SurveyForm from 'rebates/steps/SurveyForm';
import {RebateContext} from 'rebates/RebateContext';
import {sendMessage} from 'utils/northText';
import SafeButton from 'shared/SafeButton';
import { FlexRow } from 'shared/styled-components';
import firebase from "firebase";
import {toast} from "react-toastify";
import parsePhoneNumber, {isValidNumberForRegion} from 'libphonenumber-js'
import InputAdornment from "@material-ui/core/InputAdornment";



/**
 * @callback onSuccess
 * @param {Object} newForm - The user's inputted data on submission.
 */

/**
 * @callback onFailure
 * @param {Object} newForm - The user's inputted data modified to include any errors found.
 */

/**
 * We expose the registration logic to decouple it from the registration form. This way we have
 * control over when validation and registration occurs (e.g. as part of a bigger form or in
 * grandparent).
 * @param {Object} form - Contains the new user data.
 * @param {onSuccess} onSuccess - Callback fired upon success user creation.
 * @param {onFailure} onFailure - Callback fired when there was an error validating or creating user.
 */
export const register = (form: RegistrationForm, onSuccess, onFailure) => {
  if (form.isSubmitting) {
    return;
  }

  const newForm = validateAccountForm(form);
  if (Boolean(newForm.error)) {
    onFailure(newForm);
    return;
  }

  const { error, isSubmitting, ...newUserData } = newForm;
  return createNewUser(newUserData)
    .then(async () => {
      if (form.isPromotionsEmailChecked) {
        await postEmailList(form.email, 'Calitopia');
      }
      if (form.isPromotionsEmailChecked) {
        await postTextList(form.phoneNumber, 'Calitopia');
        await sendMessage(form.phoneNumber);
      }
    })
    .then(() => setTimeout(() => onSuccess(), 2000))
    .catch(({ message }) => {
      onFailure({ ...newForm, error: message });
    });
};

export const registerFromSignUp = (form: RegistrationForm, otpResponse, onSuccess, onFailure) => {
    if (form.isSubmitting) {
        return;
    }
    if (form.phoneNumber) {
        const phone = parsePhoneNumber(form.phoneNumber, 'US')
        if (phone && phone.number) {
            form.phoneNumber = phone.number;
        }
    }
    const newForm = validateAccountForm(form);
    if (Boolean(newForm.error)) {
        onFailure(newForm);
        return;
    }

    const {error, isSubmitting, ...newUserData} = newForm;

    return otpResponse.confirm(form.otp).then((result) => {
        if (result.additionalUserInfo.isNewUser) {
            return createOrUpdateUser(result.user?.uid, newUserData).then(async () => {
                if (form.isPromotionsEmailChecked) {
                    await postEmailList(form.email, 'Calitopia');
                }
                if (form.isPromotionsEmailChecked) {
                    await postTextList(form.phoneNumber, 'Calitopia');
                    await sendMessage(form.phoneNumber);
                }
            }).then(() => {
                setTimeout(() => onSuccess(), 100)
            })
        } else {
            setTimeout(() => onSuccess(), 100)
            toast.error("Mobile number is already registered with us.",{position: "top-right"})
        }
    }).catch((err) => {
        toast.error(err.message,{position: "top-right"})
        console.log(err)
        setTimeout(() => onSuccess(), 100)
    })
};
/**
 * @callback onChange
 * @param {Object} event - The input event object describing the DOM element that was modified.
 *  We're only concerned with two attributes from the target element:
 *  id (identifies the textfield that was modified), and value (the new text value)
 */

export const formatPhoneNumber = (phoneNumber) => {
    // Strip all characters from the input except digits
    // Trim the remaining input to ten characters, to preserve phone number format
    const strippedPhone = phoneNumber.replace(/\D/g, '').substring(0, 10);

    // Based upon the length of the string, we add formatting as necessary
    const size = strippedPhone.length;

    return size === 10
        ? `(${strippedPhone.substring(0, 3)})${strippedPhone.substring(3, 6)}-${strippedPhone.substring(
            6,
            10
        )}`
        : strippedPhone;
};

/**
 * A registration form combining several form group components to grab all the data required to
 * create a new user.
 * @param {string} brandName - Used to add more specificity when agreeing to receive emails
 * @param {Object} form - The data used for each textfield. Key names should match the ids used here.
 * @param {onChange} onChange - Callback function when any field is modified.

 */
const NewAccountForm = ({
                            brandName = '',
                            form,
                            onChange,
                            setOtpResponse,
                            onPhoneNumberBlur,
                            isSurvey = false,
                            deleteSurvey = false,
                            setDeleteSurvey = '',
                        }: any): ReactElement => {
    const {error = '', phoneNumber, phoneNumberError, otp, otpError} = form;
    const [otpDisable, setOtpDisable] = useState(true);
    const [otpButtonDisable, setOtpButtonDisable] = useState(false);
    const [recaptchaVerifier, setRecaptchaVerifier] = useState<any>(null);

    useEffect(() => {
        let d = new firebase.auth.RecaptchaVerifier('recaptcha-container',{
            'size': 'invisible',
        });
        setRecaptchaVerifier(d)
    }, []);

    // Mocks onChange event with formatted number
    const handlePhoneBlur = () =>
        onChange({
            target: {
                id: 'phoneNumber',
                value: phoneNumber
            }
        });
    const {surveyForm, setIsSurveyDeleteDialog} = useContext(RebateContext);

    const verifyMobile = () => {
        // console.log(verify)
        const phone = parsePhoneNumber(phoneNumber, 'US')
        if (isValidNumberForRegion(phoneNumber, 'US') && phone && phone.number) {
            return firebase.auth().signInWithPhoneNumber(phone.number, recaptchaVerifier)
                .then(( result ) => {
                    setOtpDisable(false)
                    setOtpButtonDisable(true)
                    setOtpResponse(result)
                    return toast.success("Otp has been sent successfully.",{position: "top-right"})
                })
                .catch(err => {
                    if (err?.code === 'auth/invalid-phone-number') {
                        return toast.error("Please enter valid phone number.", {position: "top-right"})
                    }
                });
        } else {
            return toast.error("Please enter valid phone number.", {position: "top-right"})
        }
    }

    return (
        <Container>
            {Boolean(error) && typeof error === 'string' ? <ErrorText>{error}</ErrorText> : null}
            <FormGroupHeader>Contact Information</FormGroupHeader>
            <SubText>
                Make an account to receive your rebate payments, check the status of your rebates, and learn
                about future discounts. We will not share your contact or banking information.
            </SubText>
            <NameFormGroup form={form} onChange={onChange}/>
            <MobileOtpFormGroup form={form} hideGlobalError onChange={onChange}/>
            <FlexRow>
            <TextField
                error={Boolean(phoneNumberError)}
                fullWidth
                helperText={phoneNumberError}
                id="phoneNumber"
                label="Phone Number"
                onBlur={handlePhoneBlur}
                onChange={onChange}
                value={phoneNumber}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            +1
                        </InputAdornment>
                    ),
                }}
                style={{marginRight: "1em"}}
                required
            />
            <SafeButton
                //disabled={otpButtonDisable}
                isSubmitting={false}
                color="primary"
                fullWidth
                style={{ background: '#00A0B0', color: 'white', borderRadius: 4, marginTop: '.6em', width: "25%", fontSize: ".9em" }}
                variant="contained"
                onClick={verifyMobile}
            >
                Send Otp
            </SafeButton>
            </FlexRow>
            <span style={{color: '#777', marginTop: '.6em', fontSize: '.9em'}}>
                We will not send you spam or share your information
            </span>
            <TextField
                disabled={otpDisable}
                error={Boolean(otpError)}
                fullWidth
                helperText={otpError}
                id="otp"
                label="Otp"
                onChange={onChange}
                value={otp}
                required
            />
            <div id="recaptcha-container"/>
            {isSurvey && surveyForm.length > 0 && !deleteSurvey && (
                <>
                    <SurveyForm/>
                    {/* <div style={{ padding: 10 }}>
            <Button
              variant="outlined"
              onClick={() => {
                setIsSurveyDeleteDialog((prev) => !prev);
              }}
            >
              Delete Survey
            </Button>
          </div> */}
                </>
            )}

            <FormGroupHeader style={{marginTop: '2em'}}>Payment Details</FormGroupHeader>
            <SubText style={{margin: '.5em 0 0 0'}}>How would you like to receive payments?</SubText>
            <PaymentFormGroup
                form={form}
                hideGlobalError
                onChange={onChange}
                style={{marginBottom: '2em'}}
            />
            {/* We don't have any Terms & Conditions so let's hide the checkbox for now */}
            <AgreementFormGroup brandName={brandName} form={form} onChange={onChange}/>

        </Container>
    );
};

// Component styles
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin
`;

const SubText = styled.p`
  font-size: 1.1em;
  text-align: left;
`;

export default NewAccountForm;
