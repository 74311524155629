import axios from 'axios';

// Using https://corsanywhere.herokuapp.com to bypass the cors errors.
axios.defaults.baseURL = 'https://corsanywhere.herokuapp.com/https://api.northtext.com/api/v2/';

// North Text API key
axios.defaults.headers.common['X-API-Key'] = 'zHWOFeIRdP4C7TJ3QbYgz3rfWgjyo1cR';

// Content Type
axios.defaults.headers.common['Content-Type'] = 'application/json';

/**
 * To send the text message using North Text API
 * @param {String} contact  - US Phone Number
 */
export const sendMessage = async (contact) => {
  try {
    const message =
      'Please respond Y for Yes or N for No to confirm that you signed up to receive text messages from Calitopia and its partners.';
    const body = {
      body: message,
      to: [contact]
    };
    const response = await axios.post('/message', body);

  } catch (err) {
    console.log(err);
  }
};
