// External Dependencies
import FormGroup from '@material-ui/core/FormGroup';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { ErrorText } from 'shared/styled-components';
import { MobileOtpForm as MobileOtpInterface } from 'shared/validation';

/**
 * @callback onChange
 * @param {Object} event - The input event object describing the DOM element that was modified.
 *  We're only concerned with two attributes from the target element:
 *  id (identifies the textfield that was modified), and value (the new text value)
 */

/**
 * Displays a pair of textfields to log into our app.
 * @param {Object} form - The data used for each textfield. Key names should match the ids used here.
 * @param {onChange} onChange - Callback function when any field is modified
 * @param {boolean} hideGlobalError - When true we do not display any general error inside form.
 *  Errors at the field level are always displayed.
 */
const MobileOtpFormGroup = ({
  form: { error, email, emailError },
  hideGlobalError = false,
  onChange = (_) => {}
}: MobileOtpFormGroupProps) => {
  const showError = !hideGlobalError && Boolean(error) && typeof error === 'string';
  return (
    <FormGroup>
      {showError && <ErrorText>{error}</ErrorText>}
      <TextField
        error={Boolean(emailError)}
        fullWidth
        helperText={emailError}
        id="email"
        label="Email"
        onChange={onChange}
        value={email}
        required
      />
    </FormGroup>
  );
};

interface MobileOtpFormGroupProps {
  form: MobileOtpInterface;
  hideGlobalError?: boolean;
  onChange: any;
}

export default MobileOtpFormGroup;
