// External Dependencies
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useHistory, useLocation} from 'react-router-dom';

// Internal Dependencies
import FormGroup from '@material-ui/core/FormGroup';
import {SessionContext} from 'session/SessionContext';
import {FlexRow, LinkButton} from 'shared/styled-components';
import SafeButton from 'shared/SafeButton';
import {defaultLoginForm, LoginForm, validatePhone} from 'shared/validation';
import TextField from "@material-ui/core/TextField";
import firebase from "firebase";
import {toast} from "react-toastify";
import LoginFormGroup from "../shared/forms/LoginFormGroup";
import parsePhoneNumber, {isValidNumberForRegion} from "libphonenumber-js";

// Styles
const SwitchFormText = styled.p`
  margin-top: 1em;
`;

// Component Definition
const LoginDialog = ({onClose, onSwitchToRegister, onSwitchToForgotPassword, ...dialogProps}) => {
    const history = useHistory();
    const location: any = useLocation();
    const {isGetting, signIn} = useContext(SessionContext);

    const [form, setForm] = useState<LoginForm>(defaultLoginForm);
    const [otpDisable, setOtpDisable] = useState(true);
    const [otpButtonDisable, setOtpButtonDisable] = useState(false);
    const [recaptchaVerifier, setRecaptchaVerifier] = useState<any>(null);
    const [otpResponse, setOtpResponse] = useState<any>(null);

    useEffect(() => {
        let recaptcha = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
        });
        setRecaptchaVerifier(recaptcha)
    }, []);

    const handleChange = ({target: {id, value}}: React.ChangeEvent<HTMLInputElement>): void =>
        setForm({...form, error: null, [`${id}Error`]: null, [id]: value});

    const resetAndCloseForm = () => {
        setForm(defaultLoginForm);

        // If we came from a different page, redirect to it
        if (location.state) {
            history.replace(location?.state?.from);
        }

        onClose();
    };

    const handleLogin = () =>
        signIn(form.phoneNumber, form.otp, otpResponse)
            .then(() => {
                resetAndCloseForm()
            })
            .catch((erroredForm) => {
                setForm(erroredForm)
            });


    const sendOtp = () => {
        const phone = parsePhoneNumber(form.phoneNumber, 'US')
        if (isValidNumberForRegion(form.phoneNumber, 'US') && phone && phone.number) {
            return firebase.auth().signInWithPhoneNumber(phone.number, recaptchaVerifier)
                .then((result) => {
                    setOtpDisable(false)
                    setOtpButtonDisable(true)
                    setOtpResponse(result)
                    return toast.success("Otp has been sent successfully.", {position: "top-right"})
                })
                .catch(err => {
                    return toast.error(err.message, {position: "top-right"})
                });
        } else {
            return toast.error('Please enter valid phone number.',{position: "top-right"})
        }
    }

    return (
        // @ts-ignore
        <Dialog onClose={resetAndCloseForm} {...dialogProps}>
            <DialogTitle>Welcome Back</DialogTitle>
            <DialogContent>
                <LoginFormGroup form={form} onChange={handleChange} otpDisable={otpDisable} sendOtp={sendOtp}/>

                {/*<SwitchFormText style={{ textAlign: 'right' }}>*/}
                {/*  <LinkButton onClick={onSwitchToForgotPassword}>Forgot Password?</LinkButton>*/}
                {/*</SwitchFormText>*/}
                <SwitchFormText>
                    Don't have a Calitopia account?&nbsp;
                    <LinkButton onClick={onSwitchToRegister}>Click here to register</LinkButton>
                </SwitchFormText>
            </DialogContent>
            <DialogActions>
                <SafeButton
                    disabled={Boolean(form.error)}
                    fullWidth
                    isSubmitting={isGetting}
                    onClick={handleLogin}
                    variant="outlined"
                >
                    Login
                </SafeButton>
            </DialogActions>
        </Dialog>
    );
};

export default LoginDialog;
