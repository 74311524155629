import React from "react";
import productImage from "../assets/productImage2.png";
import { Link } from "react-router-dom";

const RebateSection = ({ brandDetails }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "15px",
        marginBottom: "15px",
      }}
    >
        {brandDetails?.rebates.map((rb) => {
            return <div
                style={{
                    width: "1020px",
                    backgroundColor: "#F9F9F9",
                    display: "flex",
                    gap: "35px",
                    padding: "15px",
                }}
            >
                <div>
                    <img
                        style={{height: "140px", width: "65px"}}
                        src={rb.discountImage}
                        alt={"Unable to load"}
                    ></img>
                </div>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <Link to={`/r/${rb.name}`}>
                        <h1 style={{color: "#541B6F", textDecoration: "underline"}}>
                            {rb.title}
                        </h1>
                    </Link>
                    <p>
                        Equilibrium provides micro-doses of cannabinoids that your body can
                        process while keeping your mind clear and focused. Equilibrium
                        contains full-spectrum cannabis CBD, THC and naturally occurring
                        THCA.
                    </p>
                </div>
            </div>
        })}
    </div>
  );
};

export default RebateSection;
