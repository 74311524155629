import React, {useContext, useEffect, useRef, useState} from 'react';
import {Chip, Typography, withStyles} from '@material-ui/core';
import {
    Card, Box, CircularProgress, Button, Grid,
    TextField, FormControl, FormHelperText, InputLabel, Select, MenuItem
} from '@material-ui/core';
import {styles} from './styles';
import {SessionContext} from 'session/SessionContext';
import {Redirect} from 'react-router';
import {useHistory, useParams} from "react-router-dom";
import {AddEditLinkSurveyForm, defaultAddEditLinkSurveyForm, validateAddEditLinkSurveyForm} from "../shared/validation";
import {
    getAllBrands,
    getAllSurvey,
    getLinkedSurveyDetails,
    getRebatesByBrandId,
    linkSurveyWithBrandRebates
} from "../shared/api";
import {toast} from "react-toastify";


const AddEditLinkSurvey = (props) => {
    let {brandId} = useParams<{ brandId: string }>();
    const [form, setForm] = useState<AddEditLinkSurveyForm>(defaultAddEditLinkSurveyForm);
    const [brandList, setBrandList] = useState<any>([]);
    const [surveyList, setSurveyList] = useState<any>([]);
    const [rebateList, setRebateList] = useState<any>([]);
    const history = useHistory();
    const redirect = (path) => () => history.push(path);
    const {user, isGetting} = useContext(SessionContext);
    const {classes} = props;

    const handleChange = (e: { target: { id: string; value: any } }): any => {
        if (e.target.id === 'brand') {
            form.rebates = [];
            setForm({...form})
        }
        setForm({...form, [e.target.id]: e.target.value, [`${e.target.id}Error`]: null, error: null});
        if (e.target.id === 'brand') {
            getRebatesByBrand(e.target.value)
        }
    }

    useEffect(() => {
        if (brandId) {
            getLinkedSurveyDetails(brandId).then((result) => {
                if (result.length){
                    console.log(result[0])
                    // @ts-ignore
                    setForm({...form, brand: result[0] && result[0].brand ? result[0].brand : '', rebates: result[0]?.rebates, survey: result[0]?.survey})
                } else {
                    toast.error('Data not found.', {position: "top-right"});
                    setTimeout(() => {
                        history.push('/admin/linked-survey-list')
                    },1)
                }
            })
        }
        if (brandId) {
            getRebatesByBrand(brandId)
        }
        getAllBrands().then((res) => setBrandList(res));
        getAllSurvey().then((res) => setSurveyList(res));
    }, [])

    const getRebatesByBrand = (brand) => {
        getRebatesByBrandId(brand).then((res) => {
            if (res.length > 0) {
                setRebateList(res[0].rebates)
            }
        })
    }
    /**
     * To add new company to the db
     */
    const addEditLinkSurvey = async () => {
        setForm({...form, isSubmitting: true});

        if (form.isSubmitting) {
            return;
        }
        const newForm = validateAddEditLinkSurveyForm(form);
        if (Boolean(newForm.error)) {
            // @ts-ignore
            setForm({...newForm, isSubmitting: false})
            return;
        }

        return linkSurveyWithBrandRebates(form).then(() => {
            // @ts-ignore
            setForm({...newForm, isSubmitting: false})
            toast.success('Survey linked successfully', {position: "top-right"});
            setTimeout(() => {
                history.push('/admin/linked-survey-list')
            },1)
        }).catch(({err}) => {
            // @ts-ignore
            setForm({...newForm, isSubmitting: false})
            return toast.error(err.message, {position: "top-right"});
        });
    };

    const loadingComponent = (
        <Box display="flex">
            <Box m="auto">
                <CircularProgress/>
            </Box>
        </Box>
    );

    const handleChangeDropdown = (event: any) => {
        const {target: {value, name}} = event;
        setForm({...form, [name]: typeof value === 'string' ? value.split(',') : value})
    };

    if (isGetting) {
        return loadingComponent;
    }

    if (!isGetting && !user?.isAdmin) {
        return <Redirect to="/"/>;
    }

    // @ts-ignore
    return (
        <div className={classes.container}>
            <h2>Link survey with brand and rebates</h2>

            <Card className={classes.card}>
                <form onSubmit={() => 0}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <TextField size="small"
                                       disabled={!!brandId}
                                       label="Select Brand"
                                       select
                                       fullWidth
                                       id="brand"
                                       name="brand"
                                       value={form.brand}
                                       required
                                       error={Boolean(form.brandError)}
                                       helperText={form.brandError}
                                       onChange={(e) => {
                                           handleChange({target: {id: e.target.name, value: e.target.value}})
                                       }}
                                       margin="dense"
                                       variant="outlined"
                            >
                                <MenuItem value={''} disabled>Select Brand</MenuItem>
                                {brandList.map((result) => (
                                    <MenuItem key={result.id} value={result.id}>
                                        {result.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl margin="dense" variant="outlined" className={classes.formControl} size="small"
                                         fullWidth>
                                <InputLabel htmlFor="rebates-select-label">Select rebates</InputLabel>
                                <Select
                                    labelWidth={100}
                                    key="12"
                                    fullWidth
                                    id="rebates"
                                    name="rebates"
                                    value={form.rebates}
                                    required
                                    multiple
                                    error={Boolean(form.rebatesError)}
                                    onChange={handleChangeDropdown}
                                >
                                    <MenuItem value={''} disabled>Select rebates</MenuItem>
                                    {rebateList.map((result) => (
                                        <MenuItem key={result.name} value={result.name}>
                                            {result.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {form.rebatesError && <FormHelperText style={{color: "#f44336"}}>
                                    {form.rebatesError}
                                </FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl margin="dense" variant="outlined" className={classes.formControl} size="small"
                                         fullWidth>
                                <InputLabel htmlFor="demo-simple-select-label">Select survey</InputLabel>
                                <Select
                                    labelWidth={100}
                                    key="test"
                                    fullWidth
                                    id="survey"
                                    name="survey"
                                    value={form.survey}
                                    required
                                    multiple
                                    error={Boolean(form.surveyError)}
                                    onChange={handleChangeDropdown}
                                >
                                    {surveyList.map((result) => (
                                        <MenuItem key={result.surveyId} value={result.surveyId}>
                                            {result.surveyName}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {form.surveyError && <FormHelperText style={{color: "#f44336"}}>
                                    {form.surveyError}
                                </FormHelperText>}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <div style={{textAlign: 'center', marginTop: '2em'}}>
                        <Button onClick={addEditLinkSurvey} variant="outlined" color="primary">
                            {form.isSubmitting ? <CircularProgress size={20}/> : 'SAVE'}
                        </Button>
                        <Button style={{marginLeft: '1em'}} onClick={redirect('/admin/linked-survey-list')}
                                variant="outlined"
                                color="primary">
                            BACK
                        </Button>
                    </div>
                </form>
            </Card>
        </div>
    );
};

// @ts-ignore
export default withStyles(styles)(AddEditLinkSurvey);
