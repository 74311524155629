export const logos = {
  'Apex Extractions': 'https://cdn.shopify.com/s/files/1/0093/3996/1423/files/APEX_INSTAGRAM_110X100_FULL_LOGO-1_600x.jpg?v=1558477158',
  'kiva': 'https://images.squarespace-cdn.com/content/v1/54c9c23ae4b064086b1dd856/1423019961235-EQ7RC2IIZ5I455MXJDO7/ke17ZwdGBToddI8pDm48kIhw3789W9ca1q8j5id8NlVZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZamWLI2zvYWH8K3-s_4yszcp2ryTI0HqTOaaUohrI8PIp-2zGGXLJWZ9pszaJ9PemCDu5KC9RVVCuA3yj-zkONE/kiva_logo.png?format=1500w',
  'Miracle Leaf': 'https://miracleleaffl.com/wp-content/uploads/2019/06/ml-logo.png',
  'The Pairist': 'https://sparc.co/wp-content/uploads/2021/02/The-Pairist-Sonoma-County-Cannabis-Sparc.jpg',
  'Purablis': 'gs://calitopia-staging.appspot.com/rebates/rebate_purablis_tinctures_5.png',
  'Shoogies': 'https://images.squarespace-cdn.com/content/v1/5b4524755b409b10d8373672/1573358602451-26WFYMZMYGYC5X2KM6HA/ke17ZwdGBToddI8pDm48kAPi_NtmjjiS9XIY-2ozPBsUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYwL8IeDg6_3B-BRuF4nNrNcQkVuAT7tdErd0wQFEGFSnMKNXGbXjxUU2bD08usMpDxGUls4LBn2DZtzLNUp-S7rt7zvkjVRQQtW1VQtSUmWiQ/TBD_SHOO_4.png?format=2500w',
  'STIIIZY': 'https://cdn.shopify.com/s/files/1/2426/0015/t/35/assets/stiizy-popup-logo.png?8286',
};

export const redirects = {
  'stiizy': 'STIIIZY',
  'stiiizy': 'STIIIZY',
  'miracleleaf': 'Miracle Leaf',
  'purabliss': 'Purablis',
  'purablis': 'Purablis',
};

export default logos;
