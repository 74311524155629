import { Box, Button, CircularProgress, FormLabel, Switch, TextField } from '@material-ui/core';
import FileUpload, { createFileName } from './FileUpload';
import LinkForm from './LinkForm';
import RebateForm from './RebateForm';
import styled from 'styled-components';
import SlugTags from './SlugTags';
import LocationForm from './LocationForm';
import CouponForm from './CouponForm';
import { deleteFileFromFirestore, getCollectionId, uploadImageToFirestore } from 'shared/api';
import { DeleteOutline } from '@material-ui/icons';
import { useState } from 'react';

const LogoContainer = styled.div`
  flexdirection: row;
  display: flex;
  gap: 20px;
  padding: 10px 0px;
  & > div {
    flex: 1;
  }
`;

const BrandDetailsForm = (props) => {
  const {
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    values,
    isSubmitting,
    handleSubmit,
    addLink,
    addRebate,
    classes,
    addLocation,
    addCoupon
  } = props;


  const [isUploading, setIsUploading] = useState(false);
  const handleGallerySelect = async (e) => {
    if (e.target.files) {
      const type = e.target.files[0].type;
      const [name, ext] = e.target.files[0].name.split('.');
      console.log("Filename", name);
      console.log("Extension", ext);
      let isImage = false;
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
      const fileName = `gallery-${name}${Math.floor(Math.random() * 2000)}.${ext}`;
      if (validImageTypes.includes(type)) {
        isImage = true;
      }
      setIsUploading(true);
      await uploadImageToFirestore(e.target.files[0], fileName, 'gallery')
        .then((url) => {
          const result = values.gallery ? [...values.gallery, {
            isImage, link: url, fileName: fileName
          }] : [{
            isImage, link: url, fileName: fileName
          }];
          console.log("Result", result);
          setFieldValue('gallery', result);
        }).finally(() => {
          setIsUploading(false);
        })
    } else {
      console.log('Unexpected error selecting file');
    }
  };

  const handleGalleryDelete = async (fileName) => {
    try {
      await deleteFileFromFirestore(fileName, 'gallery');
      const result = values.gallery.filter((result) => result.fileName !== fileName)
      setFieldValue('gallery', result);
    }
    catch (err) {
      console.error("Error", err);
    }
  }
  return (
    <div>
      <form
        onSubmit={() => {
          return 0;
        }}
      >
        <div>
          <FormLabel>Live</FormLabel>
          <Switch
            checked={values.live}
            onChange={(e) => {
              setFieldValue(`live`, e.target.checked);
            }}
          />
        </div>
        <div>
          <TextField
            margin="dense"
            variant="outlined"
            placeholder="Enter brand name"
            fullWidth
            id="name"
            label="Brand Name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.name ? errors.name : ''}
            error={touched.name && Boolean(errors.name)}
            required
          ></TextField>
        </div>
        <div>
          <TextField
            margin="dense"
            variant="outlined"
            placeholder="Enter website"
            fullWidth
            id="website"
            label="Website"
            value={values.website}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.website ? errors.website : ''}
            error={touched.website && Boolean(errors.website)}
            required
          ></TextField>
        </div>
        <div>
          <TextField
            multiline
            rows={5}
            margin="dense"
            variant="outlined"
            placeholder="Enter description"
            fullWidth
            id="description"
            label="Description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.description ? errors.description : ''}
            error={touched.description && Boolean(errors.description)}
            required
          ></TextField>
        </div>
        <LogoContainer>
          <FormLabel>Logo</FormLabel>
          <FileUpload
            setFieldValue={setFieldValue}
            name={values.logo && values.logo.split('?')[0].split('%2F')[1]}
            id="logo"
            text="Upload Logo"
            type="brand"
          />
          {/* {values.logo ? <img alt="Logo" src={URL.createObjectURL(values.logo)} /> : ''} */}
        </LogoContainer>
        <div>
          <SlugTags {...props} />
        </div>
        <div className={classes.links}>
          <FormLabel>Locations</FormLabel>
          <div>
            <Button type="button" onClick={addLocation} variant="outlined" color="primary">
              Add Location
            </Button>
            {values?.location?.map?.((result, index) => (
              <LocationForm {...props} result={result} index={index} key={index} />
            ))}
          </div>
        </div>
        {touched.location && values.location.length === 0 && errors.location && (
          <p
            className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required MuiFormHelperText-marginDense"
            style={{ marginLeft: 0 }}
          >
            {errors.location}
          </p>
        )}

        <div className={classes.links}>
          <FormLabel>Links</FormLabel>
          <div>
            <Button type="button" onClick={addLink} variant="outlined" color="primary">
              Add Link
            </Button>
            <div>
              {values.links.map((result, index) => (
                <LinkForm key={index} {...props} result={result} index={index} />
              ))}
            </div>
          </div>
        </div>



        <Box sx={{ marginTop: 10, '& label': { marginRight: 10 } }}>
          <div className={classes.links} style={{ alignItems: 'center' }}>
            <FormLabel>Gallery</FormLabel>
            <label htmlFor={'gallery'}>
              <input onChange={handleGallerySelect} on type="file" id={'gallery'} style={{ display: 'none' }} accept="image/*,video/*" />
              <Button component="span" variant="outlined" color="primary">
                Upload
              </Button>
              <span style={{ marginLeft: 20, color: 'red' }}>{isUploading && 'Uploading...'}</span>
            </label>

          </div>

          <div style={{ display: 'flex', 'flexWrap': 'wrap', gap: 10, marginLeft: 50, marginTop: 10 }}>
            {values?.gallery?.map((result, index) => (<div key={index} style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, border: '1px solid white', padding: '10px 10px', borderRadius: 5 }}>
              {result.isImage ? <img style={{ width: 250, height: 200, objectFit: 'contain' }} src={result.link} alt="gallery" /> : <video style={{ width: 250, height: 200, objectFit: 'cover' }} src={result.link} alt="gallery" controls />}
              <Button variant="text" onClick={() => handleGalleryDelete(result.fileName)}>
                <DeleteOutline color="error" />
              </Button>
            </div>))}
          </div>

        </Box>

        <Box sx={{ marginTop: 10, '& label': { marginRight: 10 } }}>
          <FormLabel>Rebates</FormLabel>
          <Button type="button" onClick={addRebate} variant="outlined" color="primary">
            Add Rebate
          </Button>
        </Box>
        {values.rebates.map((result, index) => (
          <RebateForm {...props} key={result.id || index.toString()} result={{ ...result, id: result?.id || index }} rebateId={index} />
        ))}

        <Box sx={{ marginTop: 10, '& label': { marginRight: 10 } }}>
          <FormLabel>Coupons</FormLabel>
          <Button type="button" onClick={addCoupon} variant="outlined" color="primary">
            Add Coupons
          </Button>
        </Box>
        {values.coupons.map((result, index) => (
          <CouponForm key={result.id} {...props} result={result} couponId={index} />
        ))}
        <div style={{ textAlign: 'center', padding: 10 }}>
          <Button onClick={handleSubmit} variant="outlined" disabled={isSubmitting} color="primary">
            {isSubmitting ? <CircularProgress size={20} /> : values.id ? 'Save Changes' : 'Submit'}
          </Button>
        </div>
      </form>
    </div >
  );
};

export default BrandDetailsForm;
