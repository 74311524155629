import React, { useContext, useEffect, useState } from 'react';
import {withStyles, Card, Box, CircularProgress, Button} from '@material-ui/core';
import { useFormik } from 'formik';
import {
  BrandCouponSchema,
  BrandValidationSchema,
  CouponSchema,
  LinkSchema,
  LocationSchema,
  RebateSchema,
  SurveyQuestionSchema
} from 'utils/validationSchema';
import { deleteCouponFromDB, deleteRebateFromDB, fetchBrand, fetchCompany, getAllCategories, getCollectionId, getRebateDetails, postBrand, postCompany, postCoupon, postParticipatingStores, postRebate } from 'shared/api';
import { ToastContainer, toast } from 'react-toastify';
import BrandDetailsForm from './BrandDetailsForm';
import AddCompanyForm from './AddCompanyForm';
import SelectCompanyBrand from './SelectCompanyBrand';
import { styles } from './styles';
import { SessionContext } from 'session/SessionContext';
import { Redirect } from 'react-router';
import moment from 'moment';
import {Link} from "react-router-dom";
import styled from "styled-components";

const QRMenuButton = styled(Button)`
  &.MuiButton-root {
    margin-right: 2em;
    color: #00A0B0;
    text-transform: capitalize;
    font-weight:600;
    font-family:'Inter';
    }
  &.MuiButton-root:hover {
    background-color: transparent;
  }
`;

/**
 * Type of Questions that can be added in survey.
 */
const questionTypeList = [
  'Open Ended',
  'Select',
  'Multi-Select',
  'Yes/No',
  'Short Answer',
  'Long Answer'
];

const Brand = (props) => {
  const { classes } = props;

  //to hold and set the list of companies
  const [companyList, setCompanyList] = useState([]);

  // to hold and set the list of brands
  const [brandList, setBrandList] = useState([]);

  // to hold and set the list of categories
  const [categoryList, setCategoryList] = useState([]);

  // status of Add Company Button
  const [addCompanyButton, setAddCompanyButton] = useState(false);

  // status of AddBrandButton
  const [addBrandButton, setAddBrandButton] = useState(false);

  // to track selected brand
  const [selectedBrand, setSelectedBrand] = useState('');

  // to track selected company
  const [selectedCompany, setSelectedCompany] = useState('');

  const [deletedCoupons, setDeletedCoupons] = useState([]);
  const [deletedRebates, setDeletedRebates] = useState([]);


  /**
   * Initial values while creating brand
   */
  const initialValues = {
    company: '',
    company1: '',
    name: '',
    website: '',
    live: false,
    description: '',
    location: [],
    slugs: [],
    links: [{ title: '', url: '' }],
    logo: '',
    rebates: [],
    gallery: [],
    coupons: [],
    rebateList: [],
    couponList: [],
  };

  const [oldValues, setOldValues] = useState(initialValues);

  /**
   * Formik form handling with validationSchema: BrandValidationSchema
   */
  const {
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    setValues,
    setFieldValue,
    handleSubmit,
    setSubmitting,
    isSubmitting
  } = useFormik({
    initialValues,
    validationSchema: BrandValidationSchema,
    onSubmit: async (values) => {
      console.log('Values', values);
      values.rebateList = values.rebates.map((result) => result.name.toLowerCase());
      values.couponList = values.coupons.map((result, index) => {
        values.coupons[index].startDate = result?.startDate?._isAMomentObject ? result?.startDate.format('YYYY-MM-DD hh:mm:ss') : moment(result.startDate).format('YYYY-MM-DD hh:mm:ss');
        values.coupons[index].expirationDate = result?.expirationDate?._isAMomentObject ? result?.expirationDate.format('YYYY-MM-DD hh:mm:ss') : moment(result.expirationDate).format('YYYY-MM-DD hh:mm:ss');
        return result.name.toLowerCase()
      });
      await Promise.all(deletedCoupons.map(async (result) => await deleteCouponFromDB(result)))
      await Promise.all(deletedRebates.map(async (result) => await deleteRebateFromDB(result)))
      await addBrand(values);
    },
    enableReinitialize: true
  });

  /**
   * To add new brand to the db.
   */
  const addBrand = async (values) => {
    try {
      setSubmitting(true);
      const { company1, brand, ...rest } = values;
      const { id } = await postBrand({ values: rest });
      await Promise.all(values.rebates.map(async (result) => await postRebate({ id: await getCollectionId('Rebate'), ...result, brandId: id })))
      await Promise.all(values.coupons.map(async (result) => await postCoupon({ id: await getCollectionId('Coupon'), ...result, brandId: id, type: 'Brand' })))
      setAddBrandButton(false);
      if (values.id) {
        return toast.success('Brand updated successfully',{position: "top-right"});
      } else {
        return toast.success('Brand created successfully',{position: "top-right"});
      }
    } catch (err) {
      console.error("Error", err);
      return toast.error(err,{position: "top-right"});
    } finally {
      await getBrandList();
      setSubmitting(false);
    }
  };

  /**
   * To add new company to the db
   */
  const addCompany = async () => {
    try {
      const { company1 } = values;
      await postCompany(company1);
      setAddCompanyButton(false);
      await getCompanyList();
      return toast.success('Company created successfully',{position: "top-right"});
    } catch (err) {
      return toast.error(err,{position: "top-right"});
    }
  };

  /**
   * To delete the particular link
   * @param {number} id - Link Id
   */
  const deleteLink = (id) => {
    setFieldValue(
      'links',
      values.links.filter((_, index) => index !== id)
    );
  };

  /**
   * To delete the particular rebate
   * @param {number} id Rebate Id
   */
  const deleteRebate = (index) => {
    const rebateId = values.rebates[index].id
    if (rebateId) {
      setDeletedRebates((prev) => [...prev, rebateId]);
    }
    const result = values.rebates;
    result.splice(index, 1);
    setFieldValue(
      'rebates',
      result
    );
  };

  const deleteCoupon = (index) => {
    alert("index", index.toString());
    const couponId = values.coupons[index].id
    if (couponId) {
      setDeletedCoupons((prev) => [...prev, couponId]);
    }
    const result = values.coupons;
    result.splice(index, 1);
    setFieldValue(
      'coupons',
      result
    );
  };

  /**
   * To delete the particular survey questions from particular rebate.
   * @param {number} rebateId - Rebate ID
   * @param {number} questionId - Survey Question ID
   */
  const deleteSurveyQuestion = (rebateId, questionId) => {
    setFieldValue(
      `rebates[${rebateId}].surveyQuestions[${questionId}]`,
      values.rebates.map((result, index) => {
        if (index === rebateId) {
          return {
            ...result,
            surveyQuestions: result.surveyQuestions.filter(
              (surveyQuestion, index2) => questionId !== index2
            )
          };
        } else {
          return result;
        }
      })
    );
  };

  /**
   * To add the new link by validating whether previous links validate to the LinkSchema or not.
   */
  const addLink = () => {
    if (!errors.links) {
      if (values.links.length > 0) {
        Promise.all(values.links.map((link) => LinkSchema.validate(link))).then(() => {
          setValues((prev) => ({
            ...prev,
            links: [
              ...prev.links,
              {
                title: '',
                url: ''
              }
            ]
          }));
        });
      } else {
        setValues((prev) => ({
          ...prev,
          links: [
            {
              title: '',
              url: ''
            }
          ]
        }));
      }
    }
  };

  /**
   * To add the new rebate by validating whether previous rebates validate to the RebateSchema or not.
   */
  const addRebate = async () => {
    if (!errors.rebates) {
      const rebateId = await getCollectionId('Brand')
      values.rebates.map(async (coupon) => {
        await RebateSchema.validate(coupon);
      })
      setFieldValue(`rebates`, [{
        id: rebateId,
        name: '',
        title: '',
        discount: '',
        discountImage: '',
        disclaimer: '',
        hasSurvey: false,
        surveyQuestions: []
      }, ...values.rebates])
    }
  };

  /**
   * To add the new coupon by validating whether previous coupon validate to the CouponSchema or not.
   */
  const addCoupon = async () => {
    if (!errors.coupons) {
      values.coupons.map(async (coupon) => {
        await BrandCouponSchema.validate(coupon);
      })
      const couponId = await getCollectionId('Coupon')
      setFieldValue(`coupons`, [{
        id: couponId,
        name: '',
        categoryId: '',
        description: '',
        amountType: '',
        amount: '',
        image: '',
        startDate: moment().toDate(),
        expirationDate: moment().add(10, 'days').toDate(),
      }, ...values.coupons])
    }
  };

  /**
   * To add the new survey question to particular rebate by validating whether previous survey questions validate to the SurveyQuestionSchema or not.
   */
  const addSurveyQuestion = (rebateId) => {
    if (values.rebates[rebateId].surveyQuestions.length > 0) {
      if (!errors?.rebates?.[rebateId]?.surveyQuestions) {
        Promise.all(
          values.rebates[rebateId].surveyQuestions.map((surveyQuestion) => {
            SurveyQuestionSchema.validate(surveyQuestion);
          })
        ).then(() => {
          setFieldValue(`rebates[${rebateId}].surveyQuestions`, [
            ...values.rebates[rebateId].surveyQuestions,
            {
              type: 'Open Question',
              question: '',
              required: false,
              options: []
            }
          ]);
        });
      }
    } else {
      setFieldValue(`rebates[${rebateId}].surveyQuestions`, [
        {
          type: '',
          question: '',
          required: false,
          options: []
        }
      ]);
    }
  };

  /**
   * To fetch all the companies from db
   */
  const getCompanyList = async () => {
    try {
      const result = await fetchCompany();
      setCompanyList(result);
    } catch (err) {
      toast.error('Error fetching company list',{position: "top-right"});
    }
  };

  /**
   * To fetch all the brands of selected company
   */
  const getBrandList = async () => {
    try {
      const result = await fetchBrand(selectedCompany);
      setBrandList(result);
    } catch (err) {
      toast.error('Error fetching brand list',{position: "top-right"});
    }
  };


  const getCategoryList = async () => {
    try {
      const result = await getAllCategories();
      setCategoryList(result);
    }
    catch (err) {
      toast.error('Error fetching brand list',{position: "top-right"});
    }
  };

  useEffect(() => {
    getCompanyList();
    getCategoryList();
  }, []);

  useEffect(() => {
    setSelectedBrand('');
    if (selectedCompany) {
      setFieldValue('company', selectedCompany);
      setOldValues((prev) => ({ ...prev, company: selectedCompany }));
      getBrandList();
    } else {
      setFieldValue('company', '');
      setOldValues((prev) => ({ ...prev, company: '' }));
      setBrandList([]);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedBrand) {
      setValues(brandList.find((result) => result.name === selectedBrand));
      setOldValues(brandList.find((result) => result.name === selectedBrand));
    } else {
      setValues({ ...initialValues, company: selectedCompany });
      setOldValues({ ...initialValues, company: selectedCompany });
    }
  }, [selectedBrand]);

  /**
   * To reset the selected company,brand value when clicking add company button
   */
  const handleAddCompany = () => {
    setSelectedCompany('');
    setSelectedBrand('');
    setFieldValue('company', '');
    setAddCompanyButton(true);
  };

  /**
   * To reset the selected company,brand value when clicking add brand button
   */
  const handleAddBrand = () => {
    if (values.company) {
      setValues({
        ...initialValues,
        company: values.company,
        company1: values.company1
      });
      setAddBrandButton(true);
      setFieldValue('brand', '');
      setSelectedBrand('');
    }
  };

  /**
   * To add new locations
   */
  const addLocation = () => {
    if (values?.location?.length > 0) {
      Promise.all(values?.location?.map((loc) => LocationSchema.validate(loc))).then(() => {
        setValues((prev) => ({
          ...prev,
          location: [...prev.location, '']
        }));
      });
    } else {
      setValues((prev) => ({
        ...prev,
        location: ['']
      }));
    }
  };

  /**
   * To delete the particular location
   * @param {number} id - Location ID
   */
  const deleteLocation = (id) => {
    setFieldValue(
      'location',
      values.location.filter((_, index) => index !== id)
    );
  };
  const otherProps = {
    setFieldValue: setFieldValue,
    values: values,
    handleChange: handleChange,
    handleBlur: handleBlur,
    touched: touched,
    errors: errors,
    addCompany: addCompany,
    addLink: addLink,
    addRebate: addRebate,
    addSurveyQuestion: addSurveyQuestion,
    deleteLink: deleteLink,
    deleteRebate: deleteRebate,
    deleteSurveyQuestion: deleteSurveyQuestion,
    questionTypeList: questionTypeList,
    isSubmitting: isSubmitting,
    classes: classes,
    handleSubmit: handleSubmit,
    selectedCompany: selectedCompany,
    setSelectedCompany: setSelectedCompany,
    selectedBrand: selectedBrand,
    setSelectedBrand: setSelectedBrand,
    companyList: companyList,
    brandList: brandList,
    handleAddCompany: handleAddCompany,
    handleAddBrand: handleAddBrand,
    setAddCompanyButton: setAddCompanyButton,
    oldValues: oldValues,
    setOldValues: setOldValues,
    initialValues: initialValues,
    addLocation: addLocation,
    deleteLocation: deleteLocation,
    addCoupon: addCoupon,
    deleteCoupon: deleteCoupon,
    categoryList: categoryList,
  };

  const { user, isGetting } = useContext(SessionContext);

  const loadingComponent = (
    <Box display="flex">
      <Box m="auto">
        <CircularProgress />
      </Box>
    </Box>
  );

  if (isGetting) {
    return loadingComponent;
  }

  if (!isGetting && !user?.isAdmin) {
    return <Redirect to="/" />;
  }
  return (
    <div className={classes.container}>
      <div style={{display: "flex", justifyContent: "space-between"}}>
      <h2>Brand Page</h2>
      {/* {JSON.stringify(values, null, 2)}
      {JSON.stringify(errors, null, 2)} */}
        <QRMenuButton key="rebates-qr-code" variant="text">
          <Link style={{textDecoration: 'none', color: "#00A0B0"}} to={"/admin/rebates-qr-code"}>Generate QR For Rebates</Link>
        </QRMenuButton>
      </div>
      <Card className={classes.card}>
        <SelectCompanyBrand {...otherProps} />
        {addCompanyButton && <AddCompanyForm {...otherProps} />}
        {values.company && (addBrandButton || values.name) && !addCompanyButton && (
          <BrandDetailsForm {...otherProps} />
        )}
      </Card>
    </div>
  );
};

export default withStyles(styles)(Brand);
