import {DataGrid, GridCellParams, GridColDef, GridRowsProp} from '@material-ui/data-grid';
import React, {useState} from 'react';
import {useHistory, Link} from "react-router-dom";
import {Popover, Typography} from "@material-ui/core";

// @ts-ignore
const renderAction = (params: GridCellParams) => {
    return params.id ? (
        <Link to={'/admin/linked-survey/add/'+params.id} rel='noreferrer'>
            Edit
        </Link>
    ) : (
        <span/>
    );
}

// @ts-ignore
const RenderRows = (params) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    let val =  typeof params.row[params.field] === 'object' && params.row[params.field].length > 1 ? params.row[params.field].join(', ') : params.row[params.field]
    return params.row[params.field] ? (
        <>
            <label
                aria-describedby={'simple-popover'}
                onClick={(e) => {
                    handleClick(e);
                    e.stopPropagation();
                }}
                style={{ width: '100%' }}
            >{val}</label>
            <Popover
                id={'simple-popover'}
                open={open}
                onClose={() => handleClose()}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Typography style={{ padding: 7 }}>{val}</Typography>
            </Popover>
        </>
    ) : (
        <></>
    );
    // (<label title={params.id}>{params.id.slice(0,5)}...</label>):<label></label>
};


// @ts-ignore
const columns: GridColDef[] = [
    {field: 'brandName', headerName: 'brandName', renderCell: RenderRows, width: 150},
    {field: 'rebates', headerName: 'Rebates', renderCell: RenderRows, width: 200},
    {field: 'survey', headerName: 'Survey', renderCell: RenderRows, width: 300},
    // {field: 'surveyName', headerName: 'surveyName', flex: 1, width: 100},
    {field: 'keywords', headerName: 'keywords', renderCell: RenderRows, flex: 1, width: 200},
    {field: 'dateCreated', headerName: 'dateCreated', width: 200},
    {field: 'action', headerName: 'action', renderCell: renderAction, width: 140},
];

const LinkSurveyListTable = ({onRefetch, items}) => {
    const history = useHistory();
    const redirect = (path) => () => history.push(path);
    // Object keys must match the "field" property of columns
    const rows: GridRowsProp = items.map(
        ({dateCreated, ...rebateData}) => ({
            ...rebateData,
            dateCreated: new Date(dateCreated).toLocaleDateString(),
        }),
    );

    return (
        <>
            <div style={{marginBottom: '1em', textAlign: 'right'}}>
            <Link to={'/admin/linked-survey/add'} className="MuiButtonBase-root MuiButton-root MuiButton-outlined"
                  style={{minWidth: 'fit-content', marginTop: 4}}>
                Add New
            </Link>
            </div>
            <div>
                <DataGrid
                    autoHeight
                    columns={columns}
                    pageSize={10}
                    rows={rows}
                    getRowId={(row) => row.id}
                />
            </div>
        </>
    );
};

export default LinkSurveyListTable;
