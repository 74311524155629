// External Dependencies
import './index.css';
import App from './App';
import firebase from 'firebase/app';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from 'reportWebVitals';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'react-toastify/dist/ReactToastify.css';
import GA4React from 'ga-4-react';
const sgMail = require('@sendgrid/mail');

// TODO: This config is public by design. We need to restrict usage to calitopia.co
// https://medium.com/@devesu/how-to-secure-your-firebase-project-even-when-your-api-key-is-publicly-available-a462a2a58843
const firebaseConfig = process.env.REACT_APP_ENV === 'production' ? JSON.parse(process.env.REACT_APP_FIREBASE_CREDENTIALS_PROD) : JSON.parse(process.env.REACT_APP_FIREBASE_CREDENTIALS_DEV);


const sendGridConfig = {
  apiKey: process.env.REACT_APP_SENDER_API_KEY
};

sgMail.setApiKey(sendGridConfig.apiKey);

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  if (process.env.REACT_APP_ENV === 'local') {
    console.log('Using local firebase functions');
    firebase.functions().useEmulator('Calitopia', 5001);
  }
} else {
  console.log('Firebase already initialized', firebase.apps);
}

const ga4react = new GA4React('G-JE0KZL9J0B');
(async (_) => {
  await ga4react
    .initialize()
    .then((res) => console.log('Analytics Success.'))
    .catch((err) => console.log('Analytics Failure.'))
    .finally(() => {
      ReactDOM.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>,
        document.getElementById('root')
      );
    });
})();




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
