// External Dependencies
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

// Internal Dependencies
import StateSelect from '../StateSelect';
import { ErrorText } from '../styled-components';

const VENMO = 'venmo';
const CASH_BY_MAIL = 'cash';
const PAYPAL = 'paypal';
const CASHAPP = 'cashApp';


/**
 * @callback onChange
 * @param {Object} event - The input event object describing the DOM element that was modified.
 *  We're only concerned with two attributes from the target element:
 *  id (identifies the textfield that was modified), and value (the new text value)
 */

/**
 * Displays a collection of textfield inputs related to payment information. FormGroups depend on
 * their parent to manage form state. That way we can re-use form elements between different forms.
 * @param {boolean} disabled - When true the user cannot edit any fields
 * @param {Object} form - The data used for each textfield. Key names should match the ids used here.
 * @param {onChange} onChange - Callback function when any field is modified
 * @param {boolean} hideGlobalError - When true we do not display any general error inside form.
 *  Errors at the field level are always displayed.
 */
const PaymentFormGroup = ({
  disabled = false,
  form: {
    city,
    cityError,
    error,
    paymentMethod,
    primaryLine,
    primaryLineError,
    secondaryLine,
    secondaryLineError,
    state,
    stateError,
    venmoUsername,
    venmoUsernameError,
    paypalEmail,
    paypalEmailError,
    cashAppUsername,
    cashAppUsernameError,
    zip,
    zipError
  },
  hideGlobalError = false,
  onChange = _ => { },
  ...other
}) => {

  const venmoForm = (
    <FormGroup>
      <TextField
        error={Boolean(venmoUsernameError)}
        fullWidth
        disabled={disabled}
        helperText={venmoUsernameError}
        id="venmoUsername"
        label="Venmo Handle"
        onChange={onChange}
        value={venmoUsername}
      />
    </FormGroup>
  );


  const paypalForm = (
    <FormGroup>
      <TextField
        error={Boolean(paypalEmailError)}
        fullWidth
        disabled={disabled}
        helperText={paypalEmailError}
        id="paypalEmail"
        label="Paypal Email"
        onChange={onChange}
        value={paypalEmail}
      />
    </FormGroup>
  );

  const cashAppForm = (
    <FormGroup>
      <TextField
        error={Boolean(cashAppUsernameError)}
        fullWidth
        disabled={disabled}
        helperText={cashAppUsernameError}
        id="cashAppUsername"
        label="Cash App Username"
        onChange={onChange}
        value={cashAppUsername}
      />
    </FormGroup>
  );

  const cashForm = (
    <FormGroup>
      <TextField
        error={Boolean(primaryLineError)}
        disabled={disabled}
        fullWidth
        helperText={primaryLineError}
        id="primaryLine"
        label="Address"
        onChange={onChange}
        value={primaryLine}
      />
      <TextField
        error={Boolean(secondaryLineError)}
        disabled={disabled}
        fullWidth
        helperText={secondaryLineError}
        id="secondaryLine"
        label="Unit. Apt. Ste. number"
        onChange={onChange}
        value={secondaryLine}
      />
      <TextField
        error={Boolean(cityError)}
        disabled={disabled}
        fullWidth
        helperText={cityError}
        id="city"
        label="City"
        onChange={onChange}
        value={city}
      />
      <FormRow style={{ width: '100%' }}>
        {/* @ts-ignore */}
        <StateSelect
          error={Boolean(stateError)}
          helperText={stateError}
          id="state"
          // Manually pass id because MUI Select will not
          onChange={e => onChange({ target: { id: 'state', value: e.target.value } })}
          value={state}
        />
        <TextField
          error={Boolean(zipError)}
          disabled={disabled}
          id="zip"
          helperText={zipError}
          label="Zip"
          onChange={onChange}
          style={{ marginLeft: '1em', width: '50%' }}
          value={zip}
        />
      </FormRow>
    </FormGroup>
  );

  return (
    <FormControl component='fieldset' style={{ width: '100%' }} {...other}>
      {Boolean(error) && (typeof error === 'string') && !hideGlobalError && <ErrorText>{error}</ErrorText>}
      {/* @ts-ignore */}
      <RadioGroup onChange={onChange} row>
        <FormControlLabel
          control={<Radio id="paymentMethod" checked={paymentMethod === VENMO} />}
          label="Venmo"
          value={VENMO}
        />
        <FormControlLabel
          control={<Radio id="paymentMethod" checked={paymentMethod === CASH_BY_MAIL} />}
          label="Cash By Mail"
          value={CASH_BY_MAIL}
        />
        <FormControlLabel
          control={<Radio id="paymentMethod" checked={paymentMethod === PAYPAL} />}
          label="Paypal"
          value={PAYPAL}
        />
        <FormControlLabel
          control={<Radio id="paymentMethod" checked={paymentMethod === CASHAPP} />}
          label="Cash App"
          value={CASHAPP}
        />
      </RadioGroup>
      {paymentMethod === VENMO && venmoForm}
      {paymentMethod === CASH_BY_MAIL && cashForm}
      {paymentMethod === PAYPAL && paypalForm}
      {paymentMethod === CASHAPP && cashAppForm}

      {/* {paymentMethod === '' ? null
        : paymentMethod === VENMO ? venmoForm
          : cashForm} */}
    </FormControl>
  );
}

// Component Styles
const FormRow = styled.div`
  display: flex;
`;

export default PaymentFormGroup;
