// External Dependencies
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 3em;

  & > div {
    color: #454545;
    font-family: Raleway;
    font-size: 1.25em;
    font-weight: 400;
    display: inline;
    margin-left: 0.5em;
    vertical-align: middle;
  }

  & > svg {
    font-size: 2em;
    vertical-align: middle;
  } 
`;

// Component Definition
const ErrorState = ({ children }) => (
  <Container>
    <ErrorIcon />
    <div>{children}</div>
  </Container>
);

export default ErrorState;
