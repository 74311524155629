// External Dependencies
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useState } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import { defaultRegistrationForm, RegistrationForm } from 'shared/validation';
import { LinkButton } from 'shared/styled-components';
import NewAccountForm, {register, registerFromSignUp} from 'shared/forms/NewAccountForm';
import SafeButton from 'shared/SafeButton';

// Styles
const SwitchFormText = styled.p`
  margin-top: 1em;
`;

// Component Definition
const RegisterDialog = ({ onClose, onSwitchToLogin, ...dialogProps }) => {
  const [form, setForm] = useState<RegistrationForm>(defaultRegistrationForm);
  const [otpResponse, setOtpResponse] = useState({})

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setForm({ ...form, [e.target.id]: e.target.value, [`${e.target.id}Error`]: null, error: null });

  const handleRegister = () => {
    setForm({ ...form, isSubmitting: true });
    registerFromSignUp(
      form,
      otpResponse,
      (_) => resetAndCloseForm(), // success
      (newForm) => setForm({ ...newForm, isSubmitting: false }) // error
    );
  };

  const resetAndCloseForm = () => {
    setForm(defaultRegistrationForm);
    onClose();
  };

  return (
    // @ts-ignore
    <Dialog onClose={resetAndCloseForm} {...dialogProps}>
      <DialogTitle>Create an Account</DialogTitle>
      <DialogContent>
        <NewAccountForm form={form} setOtpResponse={setOtpResponse} onChange={handleChange} onSubmit={dialogProps.onSubmit} />
        <SwitchFormText>
          <LinkButton onClick={onSwitchToLogin}>Already have an account? Log In</LinkButton>
        </SwitchFormText>
      </DialogContent>
      <DialogActions>
        <SafeButton
          disabled={Boolean(form.error)}
          color="primary"
          fullWidth
          isSubmitting={form.isSubmitting}
          onClick={handleRegister}
          variant="contained"
        >
          Register
        </SafeButton>
      </DialogActions>
    </Dialog>
  );
};

export default RegisterDialog;
