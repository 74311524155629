// External Dependencies
import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { uploadImageToFirestore } from 'shared/api';
import styled from 'styled-components';
import firebase from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';

// Styles
const FileUploadArea = styled.div`
  border: dashed 2px rgba(138, 207, 198, 0.7);
  border-radius: 4px;
  background-color: rgba(138, 207, 198, 0.1);
  padding: 16px 0px;
  text-align: center;
`;

const FileUploadText = styled.p`
  color: #009688;
  font-weight: bold;
`;
export const createFileName = (fileName, type) => {
  const [imageName, imageType] = fileName.split('.');

  return type
    ? `${type}-${imageName}${Math.floor(Math.random() * 2000)}.${imageType}`
    : `rebate-${imageName}${Math.floor(Math.random() * 2000)}.${imageType}`;
};

// Component Definition
const FileUpload = ({ setFieldValue, id, text, name, type }) => {
  const [imageName, setImageName] = useState(name || '');
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    setImageName(name);
  }, [name]);

  const handleFileSelect = (e) => {
    if (e.target.files) {
      const fileName = createFileName(e.target.files[0].name, type);
      setUploading(true);
      uploadImageToFirestore(e.target.files[0], fileName, `${type}s`)
        .then((url) => {
          setFieldValue(id, url);
          setImageName(fileName);
        })
        .finally(() => {
          setUploading(false);
        });
    } else {
      setImageName('');
    }
  };

  return (
    <FileUploadArea>
      {uploading ? (
        <FileUploadText>
          <CircularProgress />
          <br />
          <label>Uploading</label>
        </FileUploadText>
      ) : (
        <FileUploadText>{imageName ? imageName : text || 'Upload receipt here'}</FileUploadText>
      )}
      <label htmlFor={`btn-upload-${id}`}>
        <input
          id={`btn-upload-${id}`}
          name="btn-upload"
          style={{ display: 'none' }}
          type="file"
          onChange={handleFileSelect}
          accept="image/*"
        />
        <Button
          // className="btn-choose"
          variant="outlined"
          component="span"
        >
          Choose {imageName && 'A Different '} File
        </Button>
      </label>
    </FileUploadArea>
  );
};

export default FileUpload;
