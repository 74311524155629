// External Dependencies
import Button from '@material-ui/core/Button';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import SafeButton from 'shared/SafeButton';
import { createOrUpdateUser } from 'shared/api';
import { validateUserInfo, validatePaymentDetails } from 'shared/validation';
import { ErrorText, FlexRow } from 'shared/styled-components';
import { GroupHeader, DataHeader, DataValue } from './profile-styled-components';
import PaymentFormGroup from 'shared/forms/PaymentFormGroup';
import { SessionContext } from 'session/SessionContext';
import { toast } from 'react-toastify';

const EditContainer = styled.div`
  background-color: #fdfff0;
  border: solid 1px #bdbdbd;
  border-radius: 2px;
  padding: 1em;
  margin-top: 1em;
`;

const LightText = styled.span`
  color: #9e9e9e;
`;

// Component Definition
const PaymentSettings = ({ onUpdated, userData }) => {
  const { userId } = useContext(SessionContext);

  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const [form, setForm] = useState(userData);

  const handleChange = ({ target: { id, value } }: React.ChangeEvent<HTMLInputElement>): void =>
    setForm({ ...form, error: null, [`${id}Error`]: null, [id]: value });

  useEffect(() => {
    setForm(userData);
  }, [userData])

  const {
    city,
    error,
    paymentMethod,
    primaryLine,
    isSubmitting,
    isVenmoChecked,
    secondaryLine,
    state,
    venmoUsername,
    zip,
    paypalEmail,
    cashAppUsername
  } = form;

  const updatePaymentInfo = () => {
    if (isSubmitting) {
      return;
    }

    let newForm = validateUserInfo({ ...form, changeInfo: true });
    newForm = validatePaymentDetails(newForm);

    if (Boolean(newForm.error)) {
      setForm(newForm);
      return;
    }
    setForm({ ...form, isSubmitting: true });


    const { venmoUsername, venmoUsernameError, primaryLine, secondaryLine,
      zip,
      state,
      city,
      primaryLineError,
      secondaryLineError,
      zipError,
      stateError,
      cityError,
      paypalEmail,
      paypalEmailError,
      cashAppUsername,
      cashAppUsernameError
    } = form;
    const newData = paymentMethod === 'venmo' ? { venmoUsername, venmoUsernameError, isVenmoChecked, paymentMethod } : paymentMethod === 'cash' ? {
      primaryLine, secondaryLine,
      zip,
      state,
      city,
      primaryLineError,
      secondaryLineError,
      zipError,
      stateError,
      cityError,
      paymentMethod
    } : paymentMethod === 'paypal' ? { paymentMethod, paypalEmail, paypalEmailError } : { paymentMethod, cashAppUsername, cashAppUsernameError };
    createOrUpdateUser(userId, newData)
      .then(() => {
        setForm({ ...form, isSubmitting: false, ...userData, ...newData });
        setIsEditMode(false);
        onUpdated();
        return toast.success('Payment Details Changed Successfully',{position: "top-right"});
      })
      .catch(({ message }) => setForm({ ...form, error: message, ...userData, ...newData }));
  };

  // Handle different attributes in DB that may be on user.
  let prettyPaymentMethod;
  if (paymentMethod) {
    prettyPaymentMethod = paymentMethod.toUpperCase();
  } else {
    prettyPaymentMethod = isVenmoChecked ? 'Venmo' : 'Cash By Mail';
  }

  const venmoMissingElement = <LightText>Venmo not on file</LightText>;
  const venmoElement = venmoUsername ? (venmoUsername.startsWith('@') ? venmoUsername : `@${venmoUsername}`) : '';

  const isAddressPresent = Boolean(primaryLine) && Boolean(state) && Boolean(city) && Boolean(zip);

  const addressMissingElement = <LightText>No address on file</LightText>;

  const addressElement = (
    <div>
      <DataValue>{primaryLine}</DataValue>
      <DataValue>{secondaryLine}</DataValue>
      <DataValue>
        {city}
        {' ' + state}
        {' ' + zip}
      </DataValue>
    </div>
  );

  const displayContent = (
    <div>
      <DataHeader>Preferred Payment Method</DataHeader>
      <DataValue>{prettyPaymentMethod}</DataValue>
      {paymentMethod === 'venmo' && <>  <DataHeader>Venmo Username</DataHeader>
        <DataValue>{venmoUsername ? venmoElement : venmoMissingElement}</DataValue></>}
      {paymentMethod === 'paypal' && <>
        <DataHeader>Paypal Email</DataHeader>
        <DataValue>{paypalEmail}</DataValue></>}
      {paymentMethod === 'cash' && <>
        <DataHeader>Mailing Address</DataHeader>
        <DataValue>{isAddressPresent ? addressElement : addressMissingElement}</DataValue>
      </>}
      {paymentMethod === 'cashApp' && <>  <DataHeader>Cash App Username</DataHeader>
        <DataValue>{cashAppUsername}</DataValue></>}
    </div>
  );

  const editContent = (
    <EditContainer>
      {Boolean(error) && typeof error === 'string' ? <ErrorText>{error}</ErrorText> : null}
      <PaymentFormGroup form={form} onChange={handleChange} />
      <br />
      <SafeButton
        style={{ width: '100%', marginTop: 10 }}
        onClick={updatePaymentInfo}
        isSubmitting={isSubmitting}
        variant="contained"
      >
        Save Changes
      </SafeButton>
    </EditContainer>
  );

  return (
    <div>
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <GroupHeader style={{ alignSelf: 'center' }}>Payment Details</GroupHeader>
        <Button onClick={() => setIsEditMode(!isEditMode)} size="small" variant="outlined">
          {isEditMode ? 'Cancel' : 'Edit'}
        </Button>
      </FlexRow>
      {isEditMode ? editContent : displayContent}
    </div>
  );
};

export default PaymentSettings;
