// External Dependencies
import {Button, Popover, Typography} from '@material-ui/core';
import {
    DataGrid,
    GridCellParams,
    GridColDef,

    GridRowsProp
} from '@material-ui/data-grid';
import React, {useState} from 'react';
import {useHistory, Link} from "react-router-dom";

// Internal Dependencies

// @ts-ignore
const renderAction = (params: GridCellParams) => {
    return params.id ? (
        <Link to={'/admin/add-survey/'+params.id} rel='noreferrer'>
            Edit
        </Link>
    ) : (
        <span/>
    );
}



const columns: GridColDef[] = [
    {field: 'surveyId', headerName: 'surveyId', width: 250},
    {field: 'surveyName', headerName: 'surveyName', flex: 1, width: 100},
    {field: 'dateCreated', headerName: 'dateCreated', width: 200},
    {
        field: 'action',
        headerName: 'action',
        renderCell: renderAction,
        width: 140
    },
];

const SurveyListTable = ({onRefetch, items}) => {
    const history = useHistory();
    const redirect = (path) => () => history.push(path);
    // Object keys must match the "field" property of columns
    const rows: GridRowsProp = items.map(
        ({dateCreated, ...rebateData}) => ({
            ...rebateData,
            dateCreated: new Date(dateCreated).toLocaleDateString(),
        }),
    );

    return (
        <>
            <div style={{marginBottom: '1em', textAlign: 'right'}}>
            <Link to={'/admin/add-survey'} className="MuiButtonBase-root MuiButton-root MuiButton-outlined"
                  style={{minWidth: 'fit-content', marginTop: 4}}>
                Add Survey
            </Link>
            <Link to={'/admin/linked-survey-list'} className="MuiButtonBase-root MuiButton-root MuiButton-outlined"
                      style={{minWidth: 'fit-content', marginTop: 4, marginLeft: 4}}>
                    Link Survey
            </Link>
            </div>
            <div>
                <DataGrid
                    autoHeight
                    columns={columns}
                    pageSize={10}
                    rows={rows}
                    getRowId={(row) => row.id}
                />

            </div>
        </>
    );
};

export default SurveyListTable;
