import {useContext, useEffect, useState} from 'react';
import {withStyles, Card, Box, CircularProgress, TextField, MenuItem, Button} from '@material-ui/core';
import {fetchBrand, fetchCompany} from 'shared/api';
import QRCode from "react-qr-code";

import {toast} from 'react-toastify';

import {styles} from './styles';
import {SessionContext} from 'session/SessionContext';
import {Redirect} from 'react-router';


const RebateQrCode = (props) => {
    const {classes} = props;

    //to hold and set the list of companies
    const [companyList, setCompanyList] = useState([]);

    // to hold and set the list of brands
    const [brandList, setBrandList] = useState([]);

    // to track selected brand
    const [selectedBrand, setSelectedBrand] = useState('');

    // to track selected company
    const [selectedCompany, setSelectedCompany] = useState('');

    const [rebateList, setRebateList] = useState([]);
    const [selectedRebate, setSelectedRebate] = useState('');


    /**
     * To fetch all the companies from db
     */
    const getCompanyList = async () => {
        try {
            const result = await fetchCompany();
            setCompanyList(result);
        } catch (err) {
            toast.error('Error fetching company list', {position: "top-right"});
        }
    };

    /**
     * To fetch all the brands of selected company
     */
    const getBrandList = async () => {
        try {
            const result = await fetchBrand(selectedCompany);
            setBrandList(result);
        } catch (err) {
            toast.error('Error fetching brand list', {position: "top-right"});
        }
    };


    useEffect(() => {
        getCompanyList();
    }, []);

    useEffect(() => {
        setSelectedBrand('');
        setSelectedRebate('');
        setRebateList([]);
        if (selectedCompany) {
            getBrandList();
        } else {
            setBrandList([]);
        }
    }, [selectedCompany]);

    useEffect(() => {
        setSelectedRebate("");
    }, [selectedBrand]);

    const {user, isGetting} = useContext(SessionContext);

    const loadingComponent = (
        <Box display="flex">
            <Box m="auto">
                <CircularProgress/>
            </Box>
        </Box>
    );

    if (isGetting) {
        return loadingComponent;
    }

    if (!isGetting && !user?.isAdmin) {
        return <Redirect to="/"/>;
    }

    const downloadSVGAsPNG = (e) => {
        const canvas = document.createElement("canvas");
        const svg = document.getElementById('qr-code');
        const base64doc = btoa(unescape(encodeURIComponent(svg.outerHTML)));
        const w = parseInt(svg.getAttribute('width'));
        const h = parseInt(svg.getAttribute('height'));
        const img_to_download = document.createElement('img');
        img_to_download.src = 'data:image/svg+xml;base64,' + base64doc;
        img_to_download.onload = function () {
            canvas.setAttribute('width', w);
            canvas.setAttribute('height', h);
            const context = canvas.getContext("2d");
            //context.clearRect(0, 0, w, h);
            context.drawImage(img_to_download, 0, 0, w, h);
            const dataURL = canvas.toDataURL('image/png');
            if (window.navigator.msSaveBlob) {
                window.navigator.msSaveBlob(canvas.msToBlob(), selectedRebate+"-qr.png");
                e.preventDefault();
            } else {
                const a = document.createElement('a');
                const my_evt = new MouseEvent('click');
                a.download = selectedRebate+"-qr.png";
                a.href = dataURL;
                a.dispatchEvent(my_evt);
            }
            //canvas.parentNode.removeChild(canvas);
        }
    }
    return (
        <div className={classes.container}>
            <h2>Generate QR code</h2>
            {/* {JSON.stringify(values, null, 2)}
      {JSON.stringify(errors, null, 2)} */}

            <Card className={classes.card}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        gap: 10,
                        alignItems: 'center',
                        '@media screen and (max-width:767px)': {
                            flexWrap: 'wrap'
                        }
                    }}
                >
                    <TextField
                        label="Select Company"
                        select
                        fullWidth
                        value={selectedCompany}
                        onChange={(e) => {
                            setSelectedCompany(e.target.value);
                        }}
                        margin="dense"
                        variant="outlined"
                    >
                        <MenuItem value={''}>Select Company</MenuItem>
                        {companyList.map((result) => (
                            <MenuItem key={result.id} value={result.name}>
                                {result.name}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        label="Select Brand"
                        select
                        value={selectedBrand}
                        onChange={(e) => {
                            setSelectedBrand(e.target.value);
                            let selectedBrand = brandList.filter(fl => fl.id === e.target.value);
                            if (selectedBrand.length && selectedBrand[0].rebates.length) {
                                setRebateList(selectedBrand[0].rebates)
                            } else {
                                setRebateList([])
                            }
                        }}
                        margin="dense"
                        fullWidth
                        variant="outlined"
                    >
                        <MenuItem value="">Select Brand</MenuItem>
                        {brandList.map((result) => (
                            <MenuItem key={result.id} value={result.id}>
                                {result.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Select Rebate"
                        select
                        value={selectedRebate}
                        onChange={(e) => {
                            setSelectedRebate(e.target.value);
                        }}
                        margin="dense"
                        fullWidth
                        variant="outlined"
                    >
                        <MenuItem value="">Select rebate</MenuItem>
                        {rebateList.map((result) => (
                            <MenuItem key={result.name} value={result.name}>
                                {result.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        gap: 10,
                        alignItems: 'center',
                        '@media screen and (max-width:767px)': {
                            flexWrap: 'wrap'
                        }
                    }}
                >

                    {selectedRebate && <div style={{height: "auto", marginTop: "10px", maxWidth: 200, width: "100%"}}>
                        <QRCode id="qr-code"
                                size={256}
                                style={{height: "auto", maxWidth: "100%", width: "100%"}}
                                value={`https://calitopia.co/r/${selectedRebate}`}
                                viewBox={`0 0 256 256`}
                        />
                        <Button onClick={downloadSVGAsPNG} style={{marginTop: "10px", color: "rgb(0, 160, 176)"}}>
                            Download QR Code
                        </Button>
                    </div>}
                </Box>
            </Card>
        </div>
    );
};

export default withStyles(styles)(RebateQrCode);
