import React from "react";
import productImage from "../assets/productImage.png";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";

const ProductImage = () => {
  const breakPoint1 = useMediaQuery("(min-width: 1075px)");
  return (
    <div>
      <img
        style={{
          objectFit: "contain",
          height: breakPoint1 ? "" : "450px",
          width: breakPoint1 ? "" : "600px",
        }}
        src={productImage}
        alt={"Unable to load"}
      ></img>
    </div>
  );
};

export default ProductImage;
