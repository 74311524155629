import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField
} from '@material-ui/core';
import { useState } from 'react';

const SelectStore = (props) => {
  const {
    selectedStore,
    setSelectedStore,
    storeList,
    handleAddStore,
    handleSubmit,
    values,
    oldValues,
    errors
  } = props;

  const [showDialog, setShowDialog] = useState(false);
  const [slaveSelectedStore, setSlaveSelectedStore] = useState('');

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          gap: 10,
          alignItems: 'center',
          '@media screen and (max-width:767px)': {
            flexWrap: 'wrap'
          }
        }}
      >
        <TextField
          label="Select Store"
          select
          value={selectedStore}
          style={{ width: '50%' }}
          onChange={(e) => {
            if (JSON.stringify(values) === JSON.stringify(oldValues)) {
              setSelectedStore(e.target.value);
            } else {
              setSlaveSelectedStore(e.target.value);
              setShowDialog(true);
            }
          }}
          margin="dense"
          variant="outlined"
        >
          <MenuItem value="">Select Store</MenuItem>
          {storeList.map((result) => (
            <MenuItem key={result.id} value={result.name}>
              {result.name}
            </MenuItem>
          ))}
        </TextField>
        <Button
          style={{ minWidth: 'fit-content', marginTop: 4 }}
          variant="outlined"
          onClick={handleAddStore}
        >
          Add Store
        </Button>
      </Box>
      <Dialog
        open={showDialog}
        onClose={() => {
          setShowDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>Do you want to save the changes made to {selectedStore}?</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowDialog(false);
              setSelectedStore(slaveSelectedStore);
            }}
          >
            No
          </Button>
          <Button
            onClick={async () => {
              try {
                handleSubmit();
                setTimeout(() => {
                  if (Object.keys(errors).length === 0) {
                    setSelectedStore(slaveSelectedStore);
                  }
                }, 1000);
              } catch (e) {
                console.log('Error', e);
              } finally {
                setShowDialog(false);
              }
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectStore;
