import React, {useEffect} from "react";
import webIcon from "../assets/webIcon.png";
import twitterIcon from "../assets/twitterIcon.png";
import ytIcon from "../assets/ytIcon.png";
import locationIcon from "../assets/locationIcon.png";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import {getClickableLink} from "../utils/getClickableLink";

const MiddleNav = ({brandDetails}) => {
    const breakPoint1 = useMediaQuery("(min-width: 835px)");
    const breakPoint2 = useMediaQuery("(min-width: 465px)");
    const navContent: any = [];

    if (brandDetails?.website) {
        navContent.push({
            image: webIcon,
            text: brandDetails.website,
            link: getClickableLink(brandDetails.website),
        });
    }

    brandDetails?.links?.filter((result) => ['youtube', 'twitter'].includes(result.title.toLowerCase()))
        .map((result) => {
            if (result.title === "Twitter") {
                navContent.push({
                    image: twitterIcon,
                    text: result.url,
                    link: getClickableLink(result.url),
                })
            } else if(result.title === "Youtube") {
                navContent.push({
                    image: ytIcon,
                    text: result.url,
                    link: getClickableLink(result.url),
                })
            }
        })

    if (brandDetails?.location?.length > 0) {
        navContent.push({
            image: locationIcon,
            text: "Where to Find Us",
            link: getClickableLink(brandDetails?.location[0]),
        })
    }

    return (
        <div
            style={{
                height: "100px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gap: breakPoint1 ? "50px" : "35px",
                alignItems: "center",
            }}
        >
            {navContent.map((item, k) => {
                return (
                    <div key={k}
                         style={{
                             display: "flex",
                             alignItems: "center",
                             justifyContent: "center",
                         }}
                    >
                        <div
                            style={{
                                height: "45px",
                                width: "45px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img src={item.image}></img>
                        </div>
                        {/*<a>*/}
                        {breakPoint1 ? (
                            <a target="_blank" href={item.link}>
                                <p
                                    style={{
                                        fontSize: "20px",
                                        color: "#039FAE",
                                        textDecoration: "underline",
                                    }}
                                >
                                    {item.text}
                                </p>
                            </a>
                        ) : null}
                        {/*</a>*/}
                    </div>
                );
            })}
        </div>
    );
};

export default MiddleNav;
