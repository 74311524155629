import { Switch, Route, withRouter } from 'react-router-dom';
import Admin from 'admin/Admin';
import AdminRoute from 'session/AdminRoute';
import { LandingPage, RebateShowcase } from 'marketing';
// import RebateCreation from 'admin/RebateCreation';
import UserRebateStatuses from 'rebates/status';
import SingleRebateStatus from 'rebates/status/SingleRebateStatus';
import Verify from 'verification/Verify';
import Brand from 'brand/index';
import Stores from 'stores/index';
import Rebate1 from 'rebates/Rebate1';
import BrandPage from 'brand-overview/BrandOverview';
import usePageTracking from 'utils/usePageTrackingG4';
import { SessionContext } from 'session/SessionContext';
import { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import AppBar from 'session/AppBar';
import { useLocation } from 'react-router'
import { CircularProgress } from '@material-ui/core';
import Footer from 'shared/Footer';
import QRCodeGenerator from 'qrcode';
import RebateRegistrationForm from "./rebate-registration";
import SurveyList from "./SurveyInterface/SurveyList";
import AddEditSurvey from "./SurveyInterface/addEditSurvey";
import LinkSurveyList from "./SurveyInterface/LinkSurveyList";
import AddEditLinkSurvey from "./SurveyInterface/addEditLinkSurvey";
import Advisors from "./marketing/Advisors";
import Pos from "./verification/Pos";
import BudTenderRoute from "./session/BudTenderRoute";
import RebateQrCode from "./rebate-qr-code";
import Main from "./new-brand/Main";


const Routes = (props) => {
  const { user, isGetting } = useContext(SessionContext);
  usePageTracking();
  const location = useLocation();

  const isRebateShowcasePage = location.pathname === '/rebates';

  if (isGetting && !user) {
    return (
      <div style={{ display: 'flex', width: '100vw', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress></CircularProgress>
      </div>
    );
  }

  return (
    <>
      {isRebateShowcasePage ? <></> : <AppBar />}
      <div style={{ display: 'flex', flex: 1 }}>

        <Switch>
          <AdminRoute path="/admin/brands">{user ? <Brand /> : <Redirect to="/" />}</AdminRoute>
          <AdminRoute path="/admin/stores">{user ? <Stores /> : <Redirect to="/" />}</AdminRoute>
          <AdminRoute path="/admin/survey-list">{user ? <SurveyList /> : <Redirect to="/" />}</AdminRoute>
          <AdminRoute path="/admin/add-survey/:surveyId?">{user ? <AddEditSurvey /> : <Redirect to="/" />}</AdminRoute>
          <AdminRoute path="/admin/add-survey/:surveyId?">{user ? <AddEditSurvey /> : <Redirect to="/" />}</AdminRoute>
          <AdminRoute path="/admin/linked-survey-list">{user ? <LinkSurveyList /> : <Redirect to="/" />}</AdminRoute>
          <AdminRoute path="/admin/linked-survey/add/:brandId?">{user ? <AddEditLinkSurvey /> : <Redirect to="/" />}</AdminRoute>
          <AdminRoute path="/admin/rebates-qr-code">{user ? <RebateQrCode /> : <Redirect to="/" />}</AdminRoute>
          {/* <Route path="/admin/new-rebate">{user ? <RebateCreation /> : <Redirect to="/" />}</Route> */}
          <AdminRoute path="/admin">{user ? <Admin /> : <Redirect to="/" />}</AdminRoute>
          <Route path="/verify/:id">{user ? <Verify /> : <Redirect to="/" />}</Route>
          <Route path="/verify">{user ? <Verify /> : <Redirect to="/" />}</Route>
          <Route path="/qrcode"><QRCodeGenerator /></Route>

          {/* <Route path="/r/:brandName">
      <Rebate1 />
    </Route> */}
          <Route path="/r/:rebateName">
            {/*<Rebate1 />*/}
            <RebateRegistrationForm/>
          </Route>
          <Route path="/rebates">
            <RebateShowcase />
          </Route>
          <Route path="/advisors">
            <Advisors />
          </Route>
          <Route path="/rebate/status/:id">
            {user ? <SingleRebateStatus /> : <Redirect to="/" />}
          </Route>
          <Route path="/rebate/status">{user ? <UserRebateStatuses /> : <Redirect to="/" />}</Route>
          <Route path="/brand/:brandName">
            {/*<BrandPage />*/}
            <Main/>
          </Route>

          {/*Budtender routes*/}
          {/*<BudTenderRoute path="/pos">{user ? <Pos/> : <Redirect to="/" />}</BudTenderRoute>*/}

          <Route exact path="/">
            <LandingPage />
          </Route>
          <Redirect to="/" />
        </Switch>
      </div>
      <Footer />
    </>
  );
};

export default withRouter(Routes);
