import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CardContent from '@mui/material/CardContent';
import { PageContainer } from 'shared/styled-components';
import { getAllBrands } from 'shared/api';
import CalitopiaSvg from 'shared/CalitopiaSvg';
import './styles.scss';
import { Typography } from '@material-ui/core';

const Container = styled.div`
  display: inline-block;
  padding: 1em 0.2em 1em;
  width: 23%;
  @media (max-width: 1100px) {
    display: block;
    padding-left: 0em;
    width: 45%;
  }
  @media (max-width: 768px) {
    display: block;
    padding-left: 0em;
    width: 45%;
  }
  @media (max-width: 567px) {
    display: block;
    padding-left: 0em;
    width: 90%;
  }
`;

const Logo = styled.img`
  margin: 0 auto;
  object-fit: contain;
  width: 70%;
  height: 120px;
`;

const StyledCard = styled(Card)`
  &.MuiCard-root {
    overflow: hidden;
    margin: 0;
    min-height: 20em;
    padding: 1em;
    width: 90%;
  }
  @media (max-width: 480px) {
    &.MuiCard-root {
      padding: 1em;
    }
  }
`;

const BrandCard = ({ name, title, bio, image }) => {
  return (
    <Container>
      <StyledCard elevation={3} style={{ borderRadius: 5 }}>
        <List>
          <ListItem>
            <Logo alt={name} src={`${window.location.origin}/advisors/${image}`} />
          </ListItem>
        </List>
        <CardContent>
          <Typography>{name}</Typography>
          <Typography className="title-main">{title}</Typography>
          <Typography className="bio-main">{bio}</Typography>
        </CardContent>
      </StyledCard>
    </Container>
  );
};

const Advisors = () => {
  const [advisors, setAdvisors] = useState<any>([
    {
      name: 'Paul Henderson',
      title: 'CEO of Pure UT Processing and Co-Founder of Beehive Farmacy',
      bio: 'Paul has an MBA from Carnegie Melon and has a wide range of experience in cannabis, financial services, and technology industries, having held management positions at Apple, GE Capital, and various cannabis MSOs He is a seasoned business leader with a background in finance, sales, marketing, operations, product development, and business development. As the former CEO of High Times and Grupo Flor, he gained deep insight into pivoting global brands and operating the entire supply chain of cannabis. Paul currently owns cannabis manufacturers and dispensaries in Utah.',
      image: 'paul-henderson.jpg'
    },
    {
      name: 'Sasha Kalcheff-Korn',
      title: 'Executive Director, Realm of Caring Foundation',
      bio: 'Sasha Kalcheff-Korn is a seasoned leader in the cannabis industry, currently serving as the Executive Director of the Realm of Caring Foundation. This organization empowers individuals to enhance their health and quality of life through cannabinoid research initiatives and educational programs. As the Director of Education, Sasha is deeply involved in advocating for responsible representation of cannabis history and research, as well as spreading awareness about the therapeutic potential of cannabis. Her expertise spans non-profit management, industry advocacy, and education.',
      image: 'sasha-kalcheff-korn.jpg'
    },
    {
      name: 'Jeff Ghitman',
      title: 'Experienced Entrepreneur in Cannabis, CPG and Fintech',
      bio: 'Jeff is a highly respected subject matter expert in supply chain management and finance within his focus industries. He has advised and helped scale well-known companies and brands, including across mergers and acquisitions, restructuring, and developing distribution networks for consumer-packaged goods in high-growth and emerging markets. As a fourth-generation entrepreneur, Jeff has launched and led many companies throughout his career.',
      image: 'jeff-ghitman.jpg'
    },
    {
      name: 'Jeff Jones',
      title: 'Cannabis  Pioneer, Founder of Oaksterdam',
      bio: 'Jeff Jones has provided expertise in the medical cannabis field to Californians for more than two decades. Jeff co-founded the Oakland Cannabis Buyers’ Cooperative (OCBC) in 1995. With Jeff’s assistance, the City of Oakland became the first U.S. city to contract with a medical cannabis provider in 1998, and implement a successful program to issue compassionate provider permits in June, 2004. He has been educating professionally as the horticulture expert, politics and history instructor for Oaksterdam University since 2007.',
      image: 'jeff-jones.jpg'
    },
    {
      name: 'Anne Holman',
      title: 'Owner of Co-packing Cannabis Facility',
      bio: 'Annie Holman is the Founder / CEO of The Galley, Cannabis Co-Manufacturing in Santa Rosa, CA. Annie has had a career spanning 25 years in marketing and media. She is also the former owner of California-based Derby Bakery Cannabis Edibles that produced high-end, award-winning products, winning several “Emerald Cup – Best Edibles” honors as well as first and second place prizes from “Edibles List – Best of Awards. Prior to the entering cannabis Annie ran a successful Graphic Design and PR firm for 25 years.',
      image: 'anne-holman.jpg'
    },
    {
      name: 'Jackie McGowan',
      title: 'Cannabis consultant and former candidate for governor of CA',
      bio: 'Jackie McGowan became a patient advocate on July 4, 2014. She has been a part of cannabis industry ever since, serving in roles including License Directing, Business Development and Consulting. She also is a Founder of Green Street Consulting and is a former CA Governor candidate.',
      image: 'jackie-mcgowan.jpg'
    },
    {
      name: 'Ami Mac',
      title: 'Senior Medical Director - U.S. Medical Affairs at Genentech',
      bio: 'Ami brings to the table over 25 years of experience in a broad range of industries and environments (startup, insurance, consulting, business intelligence, analytics, strategic partnership development, academia/teaching, marketing, business strategy, digital health, UI/UX, clinical practice, clinical & basic science research experience).',
      image: 'ami-mac.jpg'
    }
  ]);

  return (
    <div className="rebates-showcase advisor-main">
      <div className="banner">
        <div className="banner-background" />
        <div className="banner-content">
          <h3>Our advisors</h3>
        </div>
      </div>

      <div className="rebates-container">
        <div className="rebates-list">
          {advisors?.map?.((advisorData, index) => (
            <BrandCard key={index} {...advisorData} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Advisors;
