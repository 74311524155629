import * as yup from 'yup';

const urlRegex =
  /(http(s) ?: \/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

const passwordRegex =
  /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,20}$/;

export const SurveyQuestionSchema = yup.object().shape({
  type: yup
    .string()
    .oneOf(
      ['Open Ended', 'Select', 'Multi-Select', 'Yes/No', 'Short Answer', 'Long Answer'],
      'Please select the valid type'
    )
    .required('Please select type'),
  question: yup.string().required('Please enter question').min('1', 'Please enter valid question'),
  options: yup
    .array()
    .of(yup.string().required('Please enter valid option'))
    .when('type', {
      is: 'Select' || 'Multi-Select',
      then: yup
        .array()
        .of(yup.string().required('Please enter valid option'))
        .min(1, 'Please enter option')
    })
});

export const RebateSchema = yup.object().shape({
  name: yup.string().required('Please enter rebate name'),
  title: yup.string().required('Please enter rebate title'),
  discount: yup
    .number()
    .min(1, 'Discount cannot be 0 or negative')
    .required('Please enter discount amount'),
  discountImage: yup.string().required('Please upload discount image'),
  disclaimer: yup.string().required('Please enter disclaimer'),
  hasSurvey: yup.bool().required('Please check survey'),
  surveyQuestions: yup.array().of(SurveyQuestionSchema).min(0)
});

export const BrandCouponSchema = yup.object().shape({
  name: yup.string().required('Please enter coupon name'),
  description: yup.string().required('Please enter description'),
  amountType: yup.string().required('Please select currency or percentage'),
  amount: yup
    .number()
    .min(1, 'Amount cannot be 0 or negative')
    .required('Please enter amount'),
  image: yup.string().required('Please upload coupon image'),
  startDate: yup.date().required('Please select start date'),
  expirationDate: yup.date().required('Please select expiry date')
});

export const CouponSchema = yup.object().shape({
  type: yup.string().oneOf(['Brand', 'Store']).required('Please select coupon type'),
  brandId: yup.string().when('type', {
    is: 'Brand',
    then: yup.string().required('Please select valid brand'),
  }),
  brandInfo: yup.object().when('type', {
    is: 'Brand',
    then: yup.object().shape({
      id: yup.string(),
      name: yup.string(),
      description: yup.string(),
    })
  }),
  name: yup.string().required('Please enter coupon name'),
  // category: yup.string().required('Please select category'),
  description: yup.string().required('Please enter description'),
  amountType: yup.string().required('Please select currency or percentage'),
  amount: yup
    .number()
    .min(1, 'Amount cannot be 0 or negative')
    .required('Please enter amount'),
  image: yup.string().required('Please upload coupon image'),
  startDate: yup.date().required('Please select start date'),
  expirationDate: yup.date().required('Please select expiry date')
});

export const LinkSchema = yup.object().shape({
  title: yup.string().required('Please select title'),
  url: yup
    .string()
    .required('Please enter url')
});

export const LocationSchema = yup
  .string()
  .required('Please enter url');

export const BrandValidationSchema = yup.object().shape({
  live: yup.boolean().required(),
  name: yup.string().required('Please enter brand name'),
  website: yup.string().required('Please enter website'),
  description: yup.string().required('Please enter description'),
  location: yup.array().of(LocationSchema),
  logo: yup.mixed().required('Please upload logo'),
  links: yup.array().of(LinkSchema).min(0),
  rebates: yup.array().of(RebateSchema).min(0),
  slugs: yup.array().of(yup.string()).min(1, 'Please enter slug'),
  coupons: yup.array().of(BrandCouponSchema).min(0),
});

export const CoordinatesSchema = yup.object().shape({
  latitude: yup.number().required('Please enter latitude'),
  longitude: yup.number().required('Please enter longitude')
})

export const AddressSchema = yup.object().shape({
  state: yup.string().required('Please select state'),
  city: yup.string().required('Please enter city'),
  address1: yup.string().required('Please enter primary address'),
  address2: yup.string(),
  postalCode: yup.string().required('Please enter postal code'),
  coordinates: CoordinatesSchema
})

export const StoreValidationSchema = yup.object().shape({
  live: yup.boolean().required(),
  type: yup.string().required('Please select type').oneOf(['Dispensary', 'Delivery'], 'Please select type'),
  name: yup.string().required('Please enter store name'),
  phone: yup.string().required('Please enter phone no'),
  website: yup.string().required('Please enter website'),
  description: yup.string().required('Please enter description'),
  address: AddressSchema,
  location: yup.array().of(LocationSchema),
  logo: yup.mixed().required('Please upload logo'),
  links: yup.array().of(LinkSchema).min(0),
  coupons: yup.array().of(CouponSchema).min(0),
  slugs: yup.array().of(yup.string()).min(1, 'Please enter slug')
});

export const ChangePasswordSchema = yup.object().shape({
  currentPassword: yup.string().required('Please enter current password'),
  newPassword: yup
    .string()
    .required('Please enter new password')
    .test(
      'check-password',
      'Password should be 8-20 characters and contains at least one uppercase letter, one lowercase letter, one number and one special character',
      (value) => passwordRegex.test(value)
    )
});
