// External Dependencies
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import {
  deleteEmailList,
  postEmailList,
  deleteTextList,
  postTextList,
  createOrUpdateUser
} from 'shared/api';
import { LinkButton } from 'shared/styled-components';
import { validateEmail, validatePhone } from 'shared/validation';
import TOSDialog from '../../static/TOSDialog';
import { SessionContext } from 'session/SessionContext';

/**
 * @callback onChange
 * @param {Object} event - The input event object describing the DOM element that was modified.
 *  We're only concerned with two attributes from the target element:
 *  id (identifies the textfield that was modified), and value (the new checked value - boolean)
 */

/**
 * Displays a pair of checkboxes used to get consent to use our app and send emails.
 * @param {string} brandName - Used to add more specificity when agreeing to receive emails
 * @param {Object} form - The data used for each checkbox. Key names should match the ids used here.
 * @param {onChange} onChange - Callback function when any field is checked or unchecked.
 * @param {boolean} emailListOnly - When true we hide the checkbox for accepting our terms and conditions.
 */
const AgreementFormGroup = ({
  brandName = '',
  emailListOnly = false,
  form: {
    email,
    isTermsAndConditionsChecked = false,
    isTermsAndConditionsCheckedError = '',
    isPromotionsEmailChecked = true,
    isPromotionsTextChecked = true,
    phoneNumber
  },
  onChange = (_) => {}
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { user, userId, reloadUser } = useContext(SessionContext);

  // Utility to treat check event like a input event
  const transformEvent = (e) => ({
    ...e,
    target: {
      ...e.target,
      id: e.target.id,
      value: e.target.checked
    }
  });

  const handleCheckTerms = (e) => onChange(transformEvent(e));

  // Adds/removes email from firestore email list
  const handleCheckEmailPromotions = (e) => {
    if (!validateEmail(email)) {
      onChange(transformEvent(e));
      return;
    }

    // Default to calitopia to indiciate general emails
    if (!isPromotionsEmailChecked) {
      postEmailList(email, brandName || 'Calitopia').then(async () => {
        if (user) {
          await createOrUpdateUser(userId, { ...user, isPromotionsEmailChecked: true });
          await reloadUser();
        }
      });
    } else {
      deleteEmailList(email, brandName || 'Calitopia').then(async () => {
        if (user) {
          await createOrUpdateUser(userId, { ...user, isPromotionsEmailChecked: false });
          await reloadUser();
        }
      });
    }

    onChange(transformEvent(e));
  };

  // TODO: Send confirmation text
  const handleCheckTextPromotions = (e) => {
    if (!validatePhone(phoneNumber)) {
      onChange(transformEvent(e));
      return;
    }

    // Default to calitopia to indiciate general emails
    if (!isPromotionsTextChecked) {
      postTextList(phoneNumber, brandName || 'Calitopia').then(async () => {
        if (user) {
          await createOrUpdateUser(userId, { ...user, isPromotionsTextChecked: true });
          await reloadUser();
        }
      });
    } else {
      deleteTextList(phoneNumber, brandName || 'Calitopia').then(async () => {
        if (user) {
          await createOrUpdateUser(userId, { ...user, isPromotionsTextChecked: false });
          await reloadUser();
        }
      });
    }

    onChange(transformEvent(e));
  };

  const promotionsLabel =
    "I'd like to receive cannabis related promotions from Calitopia" +
    (brandName ? ` and ${brandName} ` : ' ');

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={isPromotionsEmailChecked}
            id="isPromotionsEmailChecked"
            onChange={handleCheckEmailPromotions}
          />
        }
        label={promotionsLabel + 'via email'}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={isPromotionsTextChecked}
            id="isPromotionsTextChecked"
            onChange={handleCheckTextPromotions}
          />
        }
        label={promotionsLabel + 'via text'}
      />
      {!emailListOnly && (
        <React.Fragment>
          <FormControlLabel
            control={
              <Checkbox
                checked={isTermsAndConditionsChecked}
                id="isTermsAndConditionsChecked"
                onChange={handleCheckTerms}
              />
            }
            label={
              <span>
                I agree with Calitopia's{' '}
                <LinkButton onClick={() => setIsOpen(true)}>
                  Terms and Conditions and Privacy Policy
                </LinkButton>
              </span>
            }
          />
          {isTermsAndConditionsCheckedError && (
            <ErrorHelperText>{isTermsAndConditionsCheckedError}</ErrorHelperText>
          )}
        </React.Fragment>
      )}
      <TOSDialog onClose={() => setIsOpen(false)} open={isOpen} />
    </FormGroup>
  );
};

// Component styles
const ErrorHelperText = styled.p`
  color: #f44336;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  letter-spacing: 0.03333em;
  line-height: 1.66;
  margin: 3px 0em 0em 2.5em;
  text-align: left;
`;

export default AgreementFormGroup;
