import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
const YoutubeEmbed = ({ embedId }) => {
  const breakPoint1 = useMediaQuery("(min-width: 768px)");

  return (
    <div>
      <iframe
        width="100%"
        height={breakPoint1 ? "250px" : "325px"}
        src={embedId}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};

export default YoutubeEmbed;
