import React, {useContext, useEffect, useState} from 'react'
import Box from '@mui/material/Box'
import Typography from "@mui/material/Typography";
import {SessionContext} from "../session/SessionContext";
import firebase from "firebase";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Redirect} from "react-router-dom";

export default function SurveyStep({setStep, brandDetails}) {
    const {user, userId, reloadUser} = useContext(SessionContext);
    const [surveyUrl, setSurveyUrl] = useState<any>('');
    const [loading, setLoading] = useState<Boolean>(false);
    // let surveyUrl = "https://www.surveylegend.com/survey/-NEFl-MpXS8P4_V2FNWK?userId=" + userId + "&brandId=" + brandDetails.id + "&rebate=" + brandDetails.rebates.name;
    useEffect(() => {
        setLoading(true)
        firebase.firestore().collection('ExternalSurveyResponses')
            .where('userId', '==', userId)
            .orderBy('completed_time', 'desc')
            .get().then((r) => {
            if (r.docs.length && r.docs[0].data()) {
                let prevSurveyId = r.docs[0].data().survey_id;
                let prevSurveyData = r.docs[0].data()
                console.log('asdasdadasdad', [r.docs[0].data().survey_id])
                firebase.firestore().collection('LinkedExternalSurvey')
                    .where('brand', '==', brandDetails.id)
                    .get().then((re) => {
                    let survy = re.docs[0].data().survey;
                    const index = survy.indexOf(prevSurveyId);
                    if (index > -1) { // only splice array when item is found
                        survy.splice(index, 1); // 2nd parameter means remove one item only
                    }
                    let filtered_survey = [];
                    firebase.firestore().collection('ExternalSurvey')
                        .where('surveyId', 'in', survy).get().then((e) => {
                        e.docs.map(da => {
                            da.data()?.keywords.map(ky => {
                                console.log(ky, 'ky')
                                prevSurveyData.answers.filter(fil => {
                                    if (fil.question.includes(ky) || (typeof fil.value === 'string' && fil.value.includes(ky))){
                                            // @ts-ignore
                                            if (!filtered_survey.includes(da.data()?.surveyId)) {
                                                // @ts-ignore
                                                filtered_survey.unshift(da.data()?.surveyId)
                                            }
                                    }
                                })
                            })
                        })
                    }).then(df => {
                        // @ts-ignore
                        setLoading(false)
                        filtered_survey = _.union(filtered_survey, survy)
                        if (filtered_survey.length > 0) {
                            setSurveyUrl("https://www.surveylegend.com/survey/" + filtered_survey[0] + "?userId=" + userId + "&brandId=" + brandDetails.id + "&rebate=" + brandDetails.rebates.name)
                        } else {
                            setStep(3)
                        }
                    })
                });
            } else {
                if (brandDetails && brandDetails.rebates) {
                    firebase.firestore().collection('LinkedExternalSurvey')
                        .where('brand', '==', brandDetails.id)
                        .where('rebates', 'in', [[brandDetails.rebates.name]])
                        .get().then((re) => {
                        if (re.docs.length && re.docs[0] && re.docs[0].data()) {
                            let surveyId = re.docs[0].data().survey[Math.floor(Math.random() * re.docs[0].data().survey.length)];
                            setSurveyUrl("https://www.surveylegend.com/survey/" + surveyId + "?userId=" + userId + "&brandId=" + brandDetails.id + "&rebate=" + brandDetails.rebates.name)
                        }
                    })
                    setLoading(false)
                } else {
                    <Redirect to={{ pathname: "/" }} />
                }

            }
        })

        window.addEventListener("message", onReceiveSurveySubmittedMessage, false);
    }, [])


    function onReceiveSurveySubmittedMessage(event: any) {
        if (event.origin === 'https://www.surveylegend.com' &&
            event.data && // null is also of type "object"
            typeof event.data === 'object' &&
            event.data.type === 'submit-responses'
        ) {
            setStep(3)
        }
    }

    return (
        <>
            <Box sx={{mt: 3, mb: 3}}>
                <Typography className="page-label" variant="h4" align="center" color="rgb(0, 160, 176)"
                            fontWeight="600">
                    Fill out a quick survey
                </Typography>
            </Box>
            <div className="survey-main">
                {loading && <Box display="flex" >
                    <Box m="auto">
                        <CircularProgress />
                    </Box>
                </Box>
                }
                {!loading && <iframe className="survey-iframe"
                        id="surveylegend-survey"
                        src={surveyUrl}
                        width="100%"
                        allowTransparency={true}
                    // style={{frameBorder: 0}}
                >
                </iframe>}
            </div>
        </>
    )
}
