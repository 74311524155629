// External Dependencies
import styled from 'styled-components';

export const DataHeader = styled.p`
  font-size: .75em;
  margin-bottom: 0;
`;

export const DataValue = styled.p`
  margin-top: .5em;
`;


export const GroupHeader = styled.h3`
  color: #757575;
  font-weight: 500;
  margin: 0em;
`;
