// External Dependencies
import firebase from 'firebase/app';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Warning from '@material-ui/icons/Warning';
import { useParams, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

// Internal Dependencies
import AgeDialog from './AgeDialog';
import BrandLogo from './BrandLogo';
import SafeButton from 'shared/SafeButton';
import { ErrorText, PageContainer } from 'shared/styled-components';
import {registerFromSignUp} from 'shared/forms/NewAccountForm';
import { SessionContext } from 'session/SessionContext';
import {
  defaultRegistrationForm,
  validateAccountForm,
  validatePaymentDetails
} from 'shared/validation';
import { getRebateDetailsByTitle, postRebateClaiming, uploadImageToFirestore } from 'shared/api';
import { PaymentStep, RebateSelectionStep, ReceiptStep } from './steps';
import { RebateContext } from './RebateContext';
import { CircularProgress } from '@material-ui/core';
import SurveyDeleteDialog from './SurveyDeleteDialog';

export const createFileName = (rebateName, type) => {
  const ext = type.split('/')[1];

  // Generate unique id
  return `${rebateName}_${firebase.auth().currentUser?.uid}_${uuidv4()}.${ext}`;
};

// Component Definition
const Rebate1 = () => {
  let { rebateName } = useParams<{ rebateName: string }>();
  const [loading, setLoading] = useState(true);
  const [rebateDetails, setRebateDetails] = useState<any>('');
  const [brandDetails, setBrandDetails] = useState<any>('');

  const [isSubmitted, setIsSubmitted] = useState(false);

  const [rebateSelectionForm, setRebateSelectionForm] = useState<any>({
    error: null,
    selectedRebates: []
  });

  useEffect(() => {
    getRebateDetailsByTitle(rebateName)
      .then((result) => {
        setBrandDetails(result);
        setRebateDetails([result.rebates]);
        setRebateSelectionForm({ error: null, selectedRebates: [result.rebates] });
      })
      .catch((err) => { })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (Array.isArray(rebateDetails)) {
      setLoading(false);
    }
  }, [rebateDetails]);

  const history = useHistory();

  // Local state
  const { user } = useContext(SessionContext);
  const [isRecaptchaValid, setIsRecaptchaValid] = useState(false);
  const [otpDisable, setOtpDisable] = useState(true);
  const [otpButtonDisable, setOtpButtonDisable] = useState(false);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<any>(null);
  const [otpResponse, setOtpResponse] = useState<any>(null);

  // Setting up context
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paymentForm, setPaymentForm] = useState<any>({ ...defaultRegistrationForm, ...user });

  const [receiptUploadForm, setReceiptUploadForm] = useState({ imageFile: null });

  const [surveyForm, setSurveyForm] = useState([]);
  const [surveyError, setSurveyError] = useState(true);
  const [surveyResult, setSurveyResult] = useState<any>([]);

  // To allow the user to delete the survey questions if they don't want to fill up.
  const [deleteSurvey, setDeleteSurvey] = useState(false);

  const [isSurveyDeleteDialog, setIsSurveyDeleteDialog] = useState(false);

  const contextValue = {
    error: false,
    paymentForm,
    rebateSelectionForm,
    receiptUploadForm,
    setError,
    setPaymentForm,
    setRebateSelectionForm,
    setReceiptUploadForm,
    isSubmitting,
    surveyForm,
    setSurveyForm,
    surveyError,
    setSurveyError,
    rebateDetails,
    setRebateDetails,
    brandDetails,
    surveyResult,
    setSurveyResult,
    isSubmitted,
    setIsSubmitted,
    deleteSurvey,
    setDeleteSurvey,
    isSurveyDeleteDialog,
    setIsSurveyDeleteDialog
  };

  useEffect(() => {
    // Reset form if user has logged out
    if (!user) {
      setPaymentForm(defaultRegistrationForm);
      return;
    }
    setPaymentForm({ ...defaultRegistrationForm, ...user });
  }, [user]);

  const { imageFile } = receiptUploadForm;

  // Event handlers
  const doesFormHaveError = () => {
    // Validate rebate selection step
    if (rebateSelectionForm.selectedRebates.length < 1) {
      // @ts-ignore
      setRebateSelectionForm({ ...rebateSelectionForm, error: 'No rebate selected' });
      return true;
    }

    // Validate payment step
    const newPaymentForm = !user
      ? validateAccountForm(paymentForm)
      : validatePaymentDetails(paymentForm);
    if (newPaymentForm.error) {
      setPaymentForm(newPaymentForm);
      return true;
    }

    // Validate survey form
    // @ts-ignore
    if (surveyForm.length > 0 && surveyError && !deleteSurvey) {
      // @ts-ignore
      setError('Please fill up all the survey questions');
      return true;
    }

    // Validate receipt upload step
    if (!imageFile) {
      // @ts-ignore
      setError('Missing receipt');
      return true;
    }

    if (recaptchaVerifier !== null && !isRecaptchaValid) {
      // @ts-ignore
      setError('Please complete recaptcha');
      return true;
    }

    return false;
  };

  const uploadRebateData = (isNew) =>
    // Override user defined file name to prevent any errors or abuse
    // @ts-ignore
    uploadImageToFirestore(imageFile, createFileName(rebateName, imageFile.type))
      .then((downloadUrl) => {
        if (!user) {
          throw new Error('User data not ready');
        }

        // Instead of calling the API again, we can assume the form is valid at this point in the
        // submission process
        // @ts-ignore
        const { venmoUsername, email, phoneNumber } = paymentForm;

        // @ts-ignore
        return postRebateClaiming({
          brandName: brandDetails.name,
          email: email,
          imageUrl: downloadUrl,
          selectedRebates: rebateSelectionForm.selectedRebates,
          userId: firebase.auth().currentUser!.uid,
          venmoUsername: venmoUsername,
          phoneNumber: phoneNumber,
          // @ts-ignore
          surveyResult: !deleteSurvey ? surveyResult : '',
          refund: rebateDetails
            // @ts-ignore
            .filter(({ title }) => rebateSelectionForm.selectedRebates?.includes(title))
            .reduce((acc, result: any) => acc + result.discount, 0)
        });
      })
      .then(async (docRef) => {
        if (!deleteSurvey) {
          await firebase
            .firestore()
            .collection('SurveyResult')
            .doc(
              `${firebase.auth().currentUser!.uid}${rebateSelectionForm.selectedRebates[0].name}`
            )
            .set({
              brandName: brandDetails.name,
              dateCreated: new Date().toISOString(),
              rebateName: rebateSelectionForm.selectedRebates[0].name,
              userId: firebase.auth().currentUser!.uid,
              surveyResult: surveyResult?.[0]?.questions || []
            })
            .catch((error) => {
              throw error;
            });
        }
        return docRef;
      })
      .then((docRef) => history.push(`/rebate/status/${docRef.id}`, { isNew: true }))
      .catch((e) => {
        setIsSubmitting(false);
        // @ts-ignore
        if (!isNew) setError('Error submitting rebate. Please try again later');
        window.scrollTo(0, 0);
      });

  useEffect(() => {
    if (user && isSubmitted) {
      submitRebate();
    }
  }, [user, isSubmitted]);

  const submitRebate = () => {
    // Validate form
    if (doesFormHaveError()) {
      // @ts-ignore
      window.scrollTo(0, 0);
      return;
    }

    // Show recaptcha verifier on initial submit click
    // if (!recaptchaVerifier) {
    //   const rv = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
    //     size: 'normal',
    //     callback: () => setIsRecaptchaValid(true),
    //     'expired-callback': () => {
    //       setIsRecaptchaValid(false);
    //       // @ts-ignore
    //       setError('Recaptcha is expired. Please refresh the page');
    //     }
    //   });
    //   rv.render();
    //   setRecaptchaVerifier(rv);
    //   return;
    // }

    // Form is valid, begin upload process
    setIsSubmitting(true);

    if (!user) {
      registerFromSignUp(
        paymentForm,
        otpResponse,
        (_) => {
          setIsSubmitted(true);
          uploadRebateData(true); // success
        },
        ({ error }) => {
          // error
          setError(error);
          setIsSubmitting(false);
          window.scrollTo(0, 0);
        }
      );
    } else {
      uploadRebateData(false);
    }
  };

  // const decrementStep = () => setActiveStep(activeStep - 1);
  // const incrementStep = () => setActiveStep(activeStep + 1);

  // let stepContent;

  const errorElement =
    Boolean(error) && typeof error === 'string' ? (
      <ErrorText>
        <Warning style={{ marginRight: 8, verticalAlign: 'sub' }} />
        {error}
      </ErrorText>
    ) : null;

  if (loading && rebateDetails.length > -1) {
    return (
      <div style={{ textAlign: 'center' }}>
        <CircularProgress></CircularProgress>
      </div>
    );
  }
  if (!loading && rebateDetails.length === 0) {
    return (
      <NotFoundContainer>
        <div>404 Rebates not found.</div>
      </NotFoundContainer>
    );
  }
  if (!loading && rebateDetails.length > 0) {
    return (
      // @ts-ignore
      <RebateContext.Provider value={contextValue}>
        <PageContainer style={{ marginTop: 20 }}>
          {errorElement}
          <StepContainer>
            <BrandLogo brandName={brandDetails.name} logo={brandDetails.logo} />
            <RebateSelectionStep
              // @ts-ignore
              brandName={brandDetails.name}
              // @ts-ignore
              availableRebates={rebateDetails}
              style={{ marginTop: '1em' }}
            />
            <PaymentStep style={{ margin: '2em 0em' }} deleteSurvey={deleteSurvey} setOtpResponse={setOtpResponse}/>
            <ReceiptStep style={{ marginBottom: '2em' }} />
          </StepContainer>
          <CenterContainer id="recaptcha-container"></CenterContainer>
          <CenterContainer>
            <SafeButton
              color="primary"
              isSubmitting={isSubmitting}
              onClick={submitRebate}
              variant="contained"
            >
              Submit
            </SafeButton>
          </CenterContainer>
          <AgeDialog />
          {isSurveyDeleteDialog && <SurveyDeleteDialog />}
        </PageContainer>
      </RebateContext.Provider>
    );
  }
};

// Component styles
const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 1em;
`;

const StepContainer = styled.div`
  padding: 1px 1em 0em 1em;
`;

const NotFoundContainer = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;

  @media (max-width: 726px) {
    font-size: 20px;
  }
`;

export default Rebate1;
