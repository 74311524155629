import React from "react";
import MainBannerBG from "./MainBannerBG";
import PlurabisBannerIcon from "./PlurabisBannerIcon";
import ProductImage from "./ProductImage";

import useMediaQuery from "@mui/material/useMediaQuery";

const BannerText = ({ details }) => {
    console.log(details);
  return (
    <div
      style={{
        width: "575px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h1 style={{ fontSize: "50px" }}>Equilibrium</h1>
      <p style={{ fontSize: "16px", wordWrap: "break-word" }}>
          {details?.description ? details?.description : ""}
      </p>
    </div>
  );
};

const MainBanner = ({brandDetails}) => {
  const mainBannerStyles = {
    height: "470px",
    width: "100%",
    display: "flex",
    backgroundColor: "#F1F1F1",
    margin: "0px",
    overflow: "hidden",
    justifyContent: "center",
  };

  const breakPoint1 = useMediaQuery("(min-width: 1075px)");
  const breakPoint2 = useMediaQuery("(min-width: 1050px)");
  return (
    <div style={mainBannerStyles}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "1050px",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: "100%",
              maxWidth: "1050px",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <MainBannerBG></MainBannerBG>
          </div>
          <div
            style={{
              width: "100%",
              maxWidth: "1050px",
              height: "100%",
              marginLeft: "-100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <ProductImage></ProductImage>
            {breakPoint1 ? <BannerText details={brandDetails}></BannerText> : null}
          </div>
          <div
            style={{
              width: breakPoint2 ? "1050px" : "100vw",
              height: "100%",
              marginLeft: "-100%",
              display: "flex",
              position: "relative",
            }}
          >
            <PlurabisBannerIcon details={brandDetails}></PlurabisBannerIcon>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBanner;
