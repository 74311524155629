/**
 * Style for brand page
 */
export const styles = () => ({
  card: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: '10px 20px',
    background: 'transparent'
  },
  container: {
    '& .MuiInputBase-root': {
      flexWrap: 'wrap'
    },
    background: '#EBF0EF',
    margin: '0 1em',
    width: '100%',
    '& > h2': {
      fontFamily: 'Raleway'
    }
  },
  actions: {
    float: 'right'
  },
  links: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: 10,
    marginTop: 20,
    '& > label': {
      marginTop: 5
    },
    '& > div': {
      width: '100%'
    },
    '& .link': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      gap: 10,
      alignItems: 'flex-start',
      '& Button': {
        marginTop: 10
      }
    }
  },
  addressContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    '& > div': {
      width: '30%'
    }
  }
});
