// External Dependencies
import Button from '@material-ui/core/Button';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Menu from '@material-ui/icons/Menu';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';

// Internal Dependencies
import { AppContext } from '../AppContext';
import { SessionContext } from 'session/SessionContext';
import ProfileDialog from 'profile/ProfileDialog';
import LoginDialog from './LoginDialog';
import RegisterDialog from './RegisterDialog';
import CalitopiaSvg from 'shared/CalitopiaSvg';
import ForgotPasswordDialog from './ForgotPasswordDialog';
import { Link } from "react-router-dom";


// Styles
const RootContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0.5em 0.5em 0.5em;
  padding:8px;
  z-index: 2;
  background:white;
  align-items:center;
`;

const MenuButton = styled(Button)`
  &.MuiButton-root {
    margin-right: 2em;
    color: #00A0B0;
    text-transform: capitalize;
    font-weight:600;
    font-family:'Inter';
    }
`;

const WhiteIconButton = styled(IconButton)`
  &:hover, &.Mui-focusVisible: {
    backgroundColor: white;
  }
`

// Component Definition
const AppBar = () => {
  const {
    appBar: { dialogType, isOpen, hideModal, showLoginModal, showProfileModal, showSignUpModal, showForgotPasswordModal }
  } = useContext(AppContext);

  const { user, signOut } = useContext(SessionContext);
  const location = useLocation();
  const history = useHistory();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  // Monitor device width and therefore type
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  // Rebate page shows our registration form by default so we hide the sign up button
  const isRebatePage = location.pathname.startsWith('/r/');

  const isRebateShowcasePage = location.pathname === '/rebates';

  const redirect = (path) => () => history.push(path);

  const actions = user
    ? [
          !user?.isBudTender && (
              <MenuButton key="advisors" variant="text">
                  <Link style={{textDecoration: 'none', color: "#00A0B0"}} to={"/advisors"}>Advisors</Link>
              </MenuButton>
          ),
          user?.isAdmin && (
              <MenuButton key="admin" onClick={redirect('/admin')} variant="text">
                  Admin
              </MenuButton>
          ),
          // <MenuButton key='brands' onClick={redirect('/brands')} variant='text'>
          //   Brands
          // </MenuButton>,
          user?.isAdmin && (
              <MenuButton key="rebates" onClick={redirect('/admin/brands')} variant="text">
                  Brand
              </MenuButton>
          ),
          user?.isAdmin && (
              <MenuButton key="survey-list" onClick={redirect('/admin/survey-list')} variant="text">
                  Surveys
              </MenuButton>
          ),
          user?.isAdmin && (
            <MenuButton key="stores" onClick={redirect('/admin/stores')} variant="text">
              Stores
            </MenuButton>
          ),
          user?.isBudTender && (
              <MenuButton key="pos" onClick={redirect('/pos')} variant="text">POS</MenuButton>
          ),
          !user?.isBudTender && (
              <MenuButton key="status" onClick={redirect('/rebate/status')} variant="text">Status</MenuButton>
          ),
          !user?.isBudTender && (
              <MenuButton key="profile" onClick={showProfileModal} variant="text">
                  My Profile
              </MenuButton>),
          <MenuButton key="logout" onClick={signOut} variant="text">
            Log Out
          </MenuButton>
    ]
    : [
          <MenuButton key="advisors" variant="text">
            <Link style={{textDecoration: 'none', color: "#00A0B0"}} to={"/advisors"}>Advisors</Link>
          </MenuButton>,
      !isRebatePage && (
        <MenuButton key="signup" color="primary" onClick={showSignUpModal} variant="outlined" style={{ background: '#00A0B0', color: 'white', borderRadius: 4 }}>
          Sign Up
        </MenuButton>
      ),
      <MenuButton key="login" onClick={showLoginModal} variant="text">
        Login
      </MenuButton>
    ];

  return (
    <RootContainer className="header">
      <div onClick={redirect('/')}>
          <CalitopiaSvg height="2.5em" style={{ cursor:"pointer", opacity: isRebateShowcasePage ? 1 : 1 }} />
      </div>
      {isMobile ? (
        <WhiteIconButton onClick={() => setIsDrawerOpen(true)}>
          <Menu />
        </WhiteIconButton>
      ) : (
        <List>{actions}</List>
      )}
      <SwipeableDrawer
        anchor="right"
        open={isDrawerOpen}
        onOpen={() => setIsDrawerOpen(true)}
        onClose={() => setIsDrawerOpen(false)}
      >
        <div>{actions}</div>
      </SwipeableDrawer>
      <RegisterDialog
        onClose={hideModal}
        onSubmit={hideModal}
        onSwitchToLogin={showLoginModal}
        open={isOpen && dialogType === 'signup'}
      />
      <LoginDialog
        onClose={hideModal}
        onSwitchToRegister={showSignUpModal}
        open={isOpen && dialogType === 'login'}
        onSwitchToForgotPassword={showForgotPasswordModal}
      />
      <ForgotPasswordDialog onClose={hideModal} open={isOpen && dialogType === 'forgotPassword'} />
      <ProfileDialog onClose={hideModal} open={isOpen && dialogType === 'profile'} />
    </RootContainer>
  );
};

export default AppBar;
