import React, {useContext, useEffect, useState} from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import RegistrationStep from '../rebate-registration/RegistrationStep'
import SurveyStep from '../rebate-registration/SurveyStep'
import PaymentStep from '../rebate-registration/PaymentStep'
import Card from '@material-ui/core/Card';
import Container from '@mui/material/Container'
import "./index.scss"
import {getRebateDetailsByTitle} from "../shared/api";
import {useParams} from "react-router-dom";
import {SessionContext} from "../session/SessionContext";
import firebase from "firebase";
import {CircularProgress} from "@material-ui/core";
import {Redirect} from "react-router";

// Step titles
// const labels = ['Register', 'Fill out a quick survey', 'Upload your receipt and tell us how to pay you']

const RebateRegistrationForm = () => {
    let { rebateName } = useParams<{ rebateName: string }>();
    const {user, userId} = useContext(SessionContext);
    const [step, setStep] = useState(0)
    const [loading, setLoading] = useState(false);
    const [rebateDetails, setRebateDetails] = useState<any>('');
    const [brandDetails, setBrandDetails] = useState<any>('');
    const [labels, setLabels] = useState<any>([]);
    const [rebateSelectionForm, setRebateSelectionForm] = useState<any>({
        error: null,
        selectedRebates: []
    });

    useEffect(() => {
        getRebateDetailsByTitle(rebateName)
            .then((result) => {
                console.log(result);
                setBrandDetails(result);
                setRebateDetails([result.rebates]);
                setRebateSelectionForm({ error: null, selectedRebates: [result.rebates, result.rebates.title] });
            })
            .catch((err) => {
                window.location.replace("/")
            })
            .finally(() => {
                setLoading(false);
                if (firebase.auth().currentUser?.uid) {
                    setStep(2)
                    setLabels(['Fill out a quick survey', 'Upload your receipt and tell us how to pay you'])
                } else {
                    setStep(1)
                    setLabels(['Register', 'Fill out a quick survey', 'Upload your receipt and tell us how to pay you'])
                }
            });
    }, []);

    useEffect(() => {
        console.log('loading', loading)
    }, [loading])

    return (
        <Container className="stepper-container-main" component="main" maxWidth="md" sx={{mb: 4}}>
            {/*{loading && <div style={{width: '100%', zIndex: 9999, background: '#f1f1f1'}}>*/}
            {/*    <CircularProgress style={{position: 'absolute', top: '50%', left: '50%'}}/>*/}
            {/*</div>}*/}
            <Stepper activeStep={step-1} sx={{py: 3}} alternativeLabel>
                {labels.map((label,index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Card className="banner-card" elevation={1}>
                <img className="banner-image" alt={brandDetails.rebates ? brandDetails.rebates.name : ''}
                     src={brandDetails.rebates ? brandDetails.rebates.discountImage : ''}
                />
                <div className="rebate-title-main">
                    <div className="rebate-title-image">
                        <img alt={brandDetails ? brandDetails.title: ''} src={brandDetails ? brandDetails.logo: ''} width="100" />
                    </div>
                    <div className="banner-text">{brandDetails.rebates ? brandDetails.rebates.title : ''}</div></div>
            </Card>
            <>
                {step === 1 && (<RegistrationStep setStep={setStep}/>)}
                {step === 2 && (<SurveyStep setStep={setStep} brandDetails={brandDetails}/>)}
                {step === 3 && (<PaymentStep setStep={setStep} brandDetails={brandDetails} rebateSelectionForm={rebateSelectionForm} rebateDetails={rebateDetails}/>)}
            </>
        </Container>
    )
}

export default RebateRegistrationForm
