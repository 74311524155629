import { useGA4React } from 'ga-4-react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

const usePageTrackingG4 = () => {
  const { pathname } = useLocation();
  const ga = useGA4React();

  useEffect(() => {
    if (ga) {
      ga.pageview(pathname);
      // console.log('GA', ga);
      // console.log('path', pathname);
    }
  }, [pathname, ga]);
};

export default usePageTrackingG4;
