// External Dependencies
import Button from '@material-ui/core/Button';
import React from 'react';
import styled from 'styled-components';

// Styles
const FileUploadArea = styled.div`
  border: dashed 2px rgba(138, 207, 198, .7);
  border-radius: 4px;
  background-color: rgba(138, 207, 198, .1);
  padding: 16px 0px;
  text-align: center;
`;

const FileUploadText = styled.p`
  color: #009688;
  font-weight: bold;
`;

interface FileUploadProps {
  imageFile: File | undefined;
  setImageFile: (File) => void; 
  text?:string
}

// Component Definition
const FileUpload = ({ imageFile, setImageFile,text }: FileUploadProps) => {
  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files) {
      setImageFile(e.target.files[0]);
    } else {
    }
  };

  return (
    <FileUploadArea>
      <FileUploadText>
        {imageFile ? imageFile.name : text || 'Upload receipt here'}
      </FileUploadText>
      <label htmlFor="btn-upload">
        <input
          id="btn-upload"
          name="btn-upload"
          style={{ display: 'none' }}
          type="file"
          onChange={handleFileSelect} />
        <Button
          // className="btn-choose"
          variant="outlined"
          component="span" >
            Choose {imageFile && 'A Different '} File
        </Button>
      </label>
    </FileUploadArea>
  );
}

export default FileUpload;
