import { Box, Card, FormLabel, Switch, TextField, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers'
import { DeleteOutline } from '@material-ui/icons';
import { fetchBrand, getAllBrands } from 'shared/api';
import FileUpload from './FileUpload';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

const CouponForm = (props) => {
    const {
        handleChange,
        handleBlur,
        touched,
        errors,
        setFieldValue,
        deleteCoupon,
        couponId,
        categoryList,
        result
    } = props;

    return (
        <Card style={{ padding: 10, marginTop: 10, background: 'transparent' }}>
            <div>
                <div style={{ textAlign: 'center' }}>
                    <DeleteOutline
                        color="error"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            deleteCoupon(couponId)
                        }}
                    />
                </div>
                <div>
                    <TextField
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        placeholder="Enter name*"
                        id={`coupons[${couponId}].name`}
                        label="Coupon Name"
                        value={result.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.coupons?.[couponId]?.name ? errors.coupons?.[couponId]?.name : ''}
                        error={touched.coupons?.[couponId]?.name && Boolean(errors.coupons?.[couponId]?.name)}
                        required
                    ></TextField>
                </div>
                <div>
                    <TextField
                        fullWidth
                        select
                        margin="dense"
                        variant="outlined"
                        placeholder="Select category*"
                        id={`coupons[${couponId}].categoryId`}
                        name={`coupons[${couponId}].categoryId`}
                        label="Category"
                        value={result.categoryId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.coupons?.[couponId]?.categoryId ? errors.coupons?.[couponId]?.categoryId : ''}
                        error={touched.coupons?.[couponId]?.categoryId && Boolean(errors.coupons?.[couponId]?.categoryId)}
                        required
                    >
                        <MenuItem value=''>Select category</MenuItem>
                        {categoryList.map((result) => (<MenuItem key={result.id} value={result.id}>{result.name}</MenuItem>))}
                    </TextField>
                </div>
                <div>
                    <TextField
                        fullWidth
                        select
                        type="number"
                        margin="dense"
                        variant="outlined"
                        placeholder="Select Amount Type"
                        id={`coupons[${couponId}].amountType`}
                        name={`coupons[${couponId}].amountType`}
                        label="Amount Type"
                        value={result.amountType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                            touched.coupons?.[couponId]?.amountType ? errors.coupons?.[couponId]?.amountType : ''
                        }
                        error={
                            touched.coupons?.[couponId]?.amountType && Boolean(errors.coupons?.[couponId]?.amountType)
                        }
                        required
                    >
                        <MenuItem value=''>Select Amount Type</MenuItem>
                        <MenuItem value='$'>Currency</MenuItem>
                        <MenuItem value='%'>Percentage</MenuItem>
                    </TextField>
                </div>
                <div>
                    <TextField
                        fullWidth
                        type="number"
                        margin="dense"
                        variant="outlined"
                        placeholder="Enter amount"
                        id={`coupons[${couponId}].amount`}
                        label="Amount"
                        value={result.amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                            touched.coupons?.[couponId]?.amount ? errors.coupons?.[couponId]?.amount : ''
                        }
                        error={
                            touched.coupons?.[couponId]?.amount && Boolean(errors.coupons?.[couponId]?.amount)
                        }
                        required
                    ></TextField>
                </div>
                <div>
                    <TextField
                        multiline
                        rows={5}
                        margin="dense"
                        variant="outlined"
                        placeholder="Enter description"
                        fullWidth
                        id={`coupons[${couponId}].description`}
                        label="Description"
                        value={result.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                            touched.coupons?.[couponId]?.description ? errors.coupons?.[couponId]?.description : ''
                        }
                        error={
                            touched.coupons?.[couponId]?.description && Boolean(errors.coupons?.[couponId]?.description)
                        }
                        required
                    ></TextField>
                </div>
                <Box sx={{ marginTop: 10 }}>
                    <FileUpload
                        setFieldValue={setFieldValue}
                        id={`coupons[${couponId}].image`}
                        name={result?.image && result?.image.split('?')[0].split('%2F')[1]}
                        text="Upload Image"
                        type="coupon"
                    />
                    {errors?.coupons?.[couponId]?.image ? (
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required MuiFormHelperText-marginDense">
                            {' '}
                            {errors.coupons[couponId].image}
                        </p>
                    ) : (
                        ''
                    )}
                    {/* {values.coupons?.[couponId]?.discountImage ? (
        <img
          alt="Logo"
          src={URL.createObjectURL(values.coupons[couponId].discountImage)}
        />
      ) : (
        ''
      )} */}
                </Box>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                        label="Date&Time picker"
                        value={result.startDate}
                        format="yyyy-MM-dd hh:mm:ss"
                        onChange={(e) => {
                            console.log(e);
                            setFieldValue(`coupons[${couponId}].startDate`, moment.utc(e).toDate())
                        }}
                        id={`coupons[${couponId}].startDate`}
                        label="Start Date"
                        fullWidth
                        helperText={''}
                        error={''}
                        disablePast
                        renderInput={(params) => <TextField{...params} margin="dense"
                            variant="outlined" />}
                    />
                    <DateTimePicker
                        value={result.expirationDate}
                        format="yyyy-MM-dd hh:mm:ss"
                        onChange={(e) => {
                            console.log(e);
                            setFieldValue(`coupons[${couponId}].expirationDate`, moment.utc(e).toDate())
                        }}
                        id={`coupons[${couponId}].expirationDate`}
                        label="Expiration Date"
                        fullWidth
                        helperText={''}
                        error={''}
                        disablePast
                        renderInput={(params) => <TextField{...params} margin="dense"
                            variant="outlined" />}
                    />
                </MuiPickersUtilsProvider>
                <div>
                </div>
            </div>
        </Card >
    );
};

export default CouponForm;
