import React, {useEffect, useState} from "react";
import veggie1 from "../assets/veggie1.jpg";
import veggie2 from "../assets/veggie2.jpg";
import veggie3 from "../assets/veggie3.jpg";
import CarouselButton from "./CarouselButton";
import leftArrow from "../assets/left-arrow-w.png";
import rightArrow from "../assets/right-arrow-w.png";
import ImageGallery from "react-image-gallery";

import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";

const MediaPlayer = ({images}) => {
    const [index, setIndex] = useState(0);

    // This is where the media content is being displayed from
    const content = [veggie1, veggie2, veggie3];

    const [HUD, setHUD] = useState(false);

    const breakPoint1 = useMediaQuery("(min-width: 1075px)");
    const breakPoint2 = useMediaQuery("(min-width: 768px)");

    const turnLeft = () => {
        setIndex((index: any) => (index === content.length - 1 ? null : (index += 1)));
        console.log("changing index");
    };

    const turnRight = () => {
        setIndex((index: any) => (index === 0 ? 0 : (index -= 1)));
    };

    useEffect(() => {
        console.log(index);
    }, [index]);

    const onSlide = (e) => {
    }

    const someComponent = props => {
        // console.log(props.someProps.objectKey)
        return <div>{/* {props.someProps.objectKey} */}</div>;
    };

    return (
        <div
            style={{
                height: breakPoint2 ? "600px" : "375px",
                display: "flex",
                width: "100&",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "25px",
                paddingLeft: breakPoint1 ? "" : "15px",
                paddingRight: breakPoint1 ? "" : "15px",
            }}
        >
            <div
              style={{
                height: "100%",
                width: "1050px",
                backgroundColor: "black",
                overflow: "hidden",
                display: "flex",
              }}
              onMouseOver={() => {
                setHUD(true);
              }}
              onMouseOut={() => {
                setHUD(false);
              }}
            >
              {content.map((c, key) => {
                return (
                  <div key={key}
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      transition: "1s ease-out",
                      transform: `translateX(-${index * 100}%)`,
                    }}
                  >
                    <img
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                      src={c}
                    ></img>
                  </div>
                );
              })}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  minWidth: "100%",
                  marginLeft: `-${content.length * 100}%`,
                }}
              >
                {HUD ? (
                  <>
                    <CarouselButton
                      image={leftArrow}
                      turn={turnRight}
                    ></CarouselButton>
                    <CarouselButton
                      image={rightArrow}
                      turn={turnLeft}
                    ></CarouselButton>
                  </>
                ) : null}
              </div>
            </div>
            {/*<ImageGallery*/}
            {/*    style={{*/}
            {/*        height: "100%",*/}
            {/*        width: "1050px",*/}
            {/*        backgroundColor: "black",*/}
            {/*        overflow: "hidden",*/}
            {/*        display: "flex",*/}
            {/*    }}*/}
            {/*    items={images}*/}
            {/*    showBullets={true}*/}
            {/*    // showIndex={true}*/}
            {/*    showThumbnails={false}*/}
            {/*    lazyLoad={false}*/}
            {/*    showPlayButton={false}*/}
            {/*    renderCustomControls={someComponent}*/}
            {/*    thumbnailPosition="bottom"*/}
            {/*    onSlide={onSlide}*/}
            {/*    showFullscreenButton={images.length > 0 ? true : false}*/}
            {/*/>*/}
        </div>
    );
};

export default MediaPlayer;
