// @ts-nocheck

// Interfaces
import {array} from "yup";
import {isValidNumberForRegion} from "libphonenumber-js";

export interface LoginForm {
    phoneNumber: string;
    phoneNumberError?: string | null;
    error: boolean | string | null;
    otp: string;
    otpError?: string | null;
    isSubmitting: boolean;
}

export interface MobileOtpForm {
    email: string;
    emailError?: string | null;
    error: boolean | string | null;
    isSubmitting: boolean;
}

/**
 * Considered our master form. This form is shared between the rebate page and new account dialog.
 * Fields in this form are grouped into separate sections called FormGroups and their arrangement
 * and/or visibility may different between different the rebate page and new account dialog. As a
 * result, our validation logic for this form is broken up based on FormGroup.
 */
export interface RegistrationForm {
    city: string;
    email: string;
    emailError?: string | null;
    error?: boolean | string | null;
    firstName: string;
    firstNameError?: string | null;
    isCashChecked: boolean;
    isCashCheckedError?: string | null;
    isPromotionsEmailChecked: boolean;
    isPromotionsEmailCheckedError?: string | null;
    isPromotionsTextChecked: boolean;
    isPromotionsTextCheckedError?: string | null;
    isSubmitting: boolean;
    isTermsAndConditionsChecked: boolean;
    isTermsAndConditionsCheckedError?: string | null;
    isVenmoChecked: boolean;
    isVenmoCheckedError?: string | null;
    lastName: string;
    lastNameError?: string | null;
    password: string;
    passwordError?: string | null;
    paymentMethod?: string;
    phoneNumber: string;
    phoneNumberError?: string | null;
    primaryLine: string;
    primaryLineError?: string | null;
    secondaryLine: string;
    secondaryLineError?: string | null;
    state: string;
    stateError?: string | null;
    venmoUsername: string;
    venmoUsernameError?: string | null;
    zip: string;
    zipError?: string | null;
    otp?: string | null;
    otpError?: string | null;
    otpResponse?: object;
}

export interface StepRegistrationForm {
    email: string;
    emailError?: string | null;
    error?: boolean | string | null;
    firstName: string;
    firstNameError?: string | null;
    isPromotionsEmailChecked: boolean;
    isPromotionsEmailCheckedError?: string | null;
    isPromotionsTextChecked: boolean;
    isPromotionsTextCheckedError?: string | null;
    isSubmitting: boolean;
    isTermsAndConditionsChecked: boolean;
    isTermsAndConditionsCheckedError?: string | null;
    lastName: string;
    lastNameError?: string | null;
    // password: string;
    // passwordError?: string | null;
    phoneNumber: string;
    phoneNumberError?: string | null;
    otp?: string | null;
    otpError?: string | null;
    otpResponse?: object;
}

export interface PaymentForm {
    error?: boolean | string | null;
    isCashChecked: boolean;
    isCashCheckedError?: string | null;
    isSubmitting: boolean;
    isVenmoChecked: boolean;
    isVenmoCheckedError?: string | null;
    paymentMethod?: string;
    primaryLine: string;
    primaryLineError?: string | null;
    secondaryLine: string;
    secondaryLineError?: string | null;
    city: string;
    cityError?: string | null;
    state: string;
    stateError?: string | null;
    venmoUsername: string;
    venmoUsernameError?: string | null;
    zip: string;
    zipError?: string | null;
    paypalEmailError?: string | null;
    paypalEmail: string;
    cashAppUsername: string;
    cashAppUsernameError?: string | null;
}

export interface AddEditSurveyForm {
    error?: boolean | string | null;
    surveyName: string;
    surveyNameError?: string | null;
    surveyId: string;
    surveyIdError?: string | null;
    keywords: array;
    keywordsError?: string | null;
    isSubmitting: boolean;
}

export interface AddEditLinkSurveyForm {
    error?: boolean | string | null;
    brand: string;
    brandError?: string | null;
    rebates: any;
    rebatesError?: string | null;
    survey: any;
    surveyError?: string | null;
    isSubmitting: boolean;
}

// Default form values
export const defaultLoginForm = {
    phoneNumber: '',
    phoneNumberError: '',
    otp: '',
    otpError: '',
    error: '',
    isSubmitting: false
};

export const defaultRegistrationForm = {
    city: '',
    email: '',
    error: '',
    firstName: '',
    isCashChecked: false,
    isPromotionsEmailChecked: true,
    isPromotionsTextChecked: true,
    isSubmitting: false,
    isTermsAndConditionsChecked: false,
    isVenmoChecked: false,
    lastName: '',
    password: '',
    paymentMethod: 'venmo',
    phoneNumber: '',
    primaryLine: '',
    secondaryLine: '',
    state: '',
    venmoUsername: '',
    zip: '',
    paypalEmail: '',
    paypalEmailError: '',
    cashAppUsername: '',
    cashAppUsernameError: ''
};

export const defaultStepRegistrationForm = {
    error: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    otp: '',
    isPromotionsEmailChecked: false,
    isPromotionsTextChecked: false,
    isTermsAndConditionsChecked: false,
    isSubmitting: false,
};

export const defaultStepPaymentForm = {
    isVenmoChecked: false,
    paymentMethod: 'venmo',
    isCashChecked: false,
    primaryLine: '',
    secondaryLine: '',
    state: '',
    venmoUsername: '',
    zip: '',
    city: '',
    paypalEmail: '',
    paypalEmailError: '',
    cashAppUsername: '',
    cashAppUsernameError: '',
    isSubmitting: false,
};

export const defaultAddEditSurveyForm = {
    surveyName: '',
    surveyId: '',
    keywords: [],
    isSubmitting: false,
};

export const defaultAddEditLinkSurveyForm = {
    error: '',
    brand: '',
    rebates: [],
    survey: [],
    isSubmitting: false,
};

const zipRegex = /^[0-9-]{5,10}$/;
const nameRegex = /^[a-zA-Z-\s]+$/;
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// const phoneNumberRegex = /^(\+0?1\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/; // U.S. only
const phoneNumberRegex = /^(\+0|\+1)[0-9]{10}$/; // U.S. only
const passwordRegex = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,20}$/
const otpRegex = /^\d{6}$/

// Validators
export const validateEmail = email => emailRegex.test(email);

// export const validatePhone = phone => phoneNumberRegex.test(phone);
export const validatePhone = phone => isValidNumberForRegion(phone, 'US');

export const validatePassword = password => passwordRegex.test(password);

const validateRequiredFields = (form: any, requiredFields: Array<string>): any => {
    const newForm = {...form};
    // Check for values in required fields
    requiredFields.forEach(key => {
        const fieldIsEmpty = !form[key] || (Boolean(form[key]) && form[key].length === 0);
        if (fieldIsEmpty) {
            newForm[`${key}Error`] = 'This field is required';
        }
    });

    return newForm;
}

const hasError = form =>
    Object.keys(form)
        .filter(key => key.endsWith('Error'))
        .some(key => Boolean(form[key]));

/**
 * Validation functions. Theses functions check the validity for a group of fields before we make
 * any API calls.
 * @param {Object} form - The input data to validate against. A validation function may only be
 *  responsible for a subset of the data provided here.
 * @returns {Object} A new form object of the same type. Any errors found are added at the field
 *  level e.g. emailError describes an error on the email input field. If any field errors are
 *  found we also include `error=true` as a shorthand. The `error` key can also be type string for
 *  any general/high-level error that may occur (e.g. connectivity issues), but they are outside
 *  the scope of validation functions.
 */

export const validateUserInfo = (form: Partial<RegistrationForm>): Partial<RegistrationForm> => {
    const {
        email,
        firstName,
        lastName,
        // password,
        changeInfo,
        otp
    } = form;

    let requiredFields = changeInfo ? ['email', 'firstName', 'lastName'] : ['email', 'firstName', 'lastName'];
    let newForm = {...form};

    if (!validateEmail(email)) {
        newForm.emailError = 'You must use a valid email';
    }

    if (!firstName) {
        newForm.firstNameError = 'Please enter a valid first name';
    }

    if (!lastName) {
        newForm.lastNameError = 'Please enter a valid last name';
    }

    if (!otp) {
        newForm.otpError = "Please enter otp."
    }

    if (!otpRegex.test(otp)) {
        newForm.otpError = "Please enter valid otp."
    }

    // if (!changeInfo && !validatePassword(password)) {
    //   newForm.passwordError = 'Password should be 8-20 characters and contains at least one uppercase letter, one lowercase letter, one number and one special character'
    // }

    newForm = {...newForm, ...validateRequiredFields(newForm, requiredFields)};

    newForm.error = hasError(newForm);

    return newForm;
};

export const validatePaymentDetails = (form: Partial<RegistrationForm>): Partial<RegistrationForm> => {
    const {
        paymentMethod,
        phoneNumber,
        zip,
        paypalEmail,
        cashAppUsername
    } = form;
    console.log("Validate", paymentMethod);
    let requiredFields: Array<string> = paymentMethod === 'cash'
        ? ['primaryLine', 'city', 'state', 'zip']
        : paymentMethod === 'venmo' ? ['venmoUsername', 'phoneNumber'] : paymentMethod === 'paypal' ? ['paypalEmail'] : ['cashAppUsername'];
    let newForm = {...form};

    if (paymentMethod === 'cash') {
        newForm.venmoUsernameError = false;
        newForm.paypalEmailError = false
        newForm.cashAppUsernameError = false;
    }

    if (paymentMethod === 'venmo') {
        newForm.primaryLineError = false;
        newForm.secondaryLineError = false;
        newForm.zipError = false;
        newForm.stateError = false;
        newForm.cityError = false;
        newForm.paypalEmailError = false;
        newForm.cashAppUsernameError = false;
    }

    if (paymentMethod === 'paypal') {
        newForm.primaryLineError = false;
        newForm.secondaryLineError = false;
        newForm.zipError = false;
        newForm.stateError = false;
        newForm.cityError = false;
        newForm.venmoUsernameError = false;
        newForm.cashAppUsernameError = false;
    }

    if (paymentMethod === 'cashApp') {
        newForm.primaryLineError = false;
        newForm.secondaryLineError = false;
        newForm.zipError = false;
        newForm.stateError = false;
        newForm.cityError = false;
        newForm.venmoUsernameError = false;
        newForm.paypalEmailError = false;
    }

    if (paymentMethod === 'cash' && !zipRegex.test(zip || '')) {
        newForm.zipError = 'Zip code is invalid';
        newForm.error = true;
    }

    if (paymentMethod === 'venmo' && !validatePhone(phoneNumber)) {
        console.log('innn')
        newForm.phoneNumberError = 'Please enter a valid phone number';
        newForm.error = true;
    }

    if (paymentMethod === 'paypal' && !validateEmail(paypalEmail)) {
        newForm.paypalEmailError = 'Please enter a valid email';
        newForm.error = true;
    }

    if (paymentMethod === 'cashApp' && !cashAppUsername.length) {
        newForm.cashAppUsernameError = 'Please enter username';
        newForm.error = true;
    }

    newForm = {...newForm, ...validateRequiredFields(newForm, requiredFields)};

    newForm.error = hasError(newForm);

    return newForm;
};

export const validatePaymentDetailsStep = (form: Partial<RegistrationForm>): Partial<RegistrationForm> => {
    const {
        paymentMethod,
        zip,
        paypalEmail,
        cashAppUsername
    } = form;
    console.log("Validate", paymentMethod);
    let requiredFields: Array<string> = paymentMethod === 'cash'
        ? ['primaryLine', 'city', 'state', 'zip']
        : paymentMethod === 'venmo' ? ['venmoUsername'] : paymentMethod === 'paypal' ? ['paypalEmail'] : ['cashAppUsername'];
    let newForm = {...form};

    if (paymentMethod === 'cash') {
        newForm.venmoUsernameError = false;
        newForm.paypalEmailError = false
        newForm.cashAppUsernameError = false;
    }

    if (paymentMethod === 'venmo') {
        newForm.primaryLineError = false;
        newForm.secondaryLineError = false;
        newForm.zipError = false;
        newForm.stateError = false;
        newForm.cityError = false;
        newForm.paypalEmailError = false;
        newForm.cashAppUsernameError = false;
    }

    if (paymentMethod === 'paypal') {
        newForm.primaryLineError = false;
        newForm.secondaryLineError = false;
        newForm.zipError = false;
        newForm.stateError = false;
        newForm.cityError = false;
        newForm.venmoUsernameError = false;
        newForm.cashAppUsernameError = false;
    }

    if (paymentMethod === 'cashApp') {
        newForm.primaryLineError = false;
        newForm.secondaryLineError = false;
        newForm.zipError = false;
        newForm.stateError = false;
        newForm.cityError = false;
        newForm.venmoUsernameError = false;
        newForm.paypalEmailError = false;
    }

    if (paymentMethod === 'cash' && !zipRegex.test(zip || '')) {
        newForm.zipError = 'Zip code is invalid';
        newForm.error = true;
    }

    if (paymentMethod === 'paypal' && !validateEmail(paypalEmail)) {
        newForm.paypalEmailError = 'Please enter a valid email';
        newForm.error = true;
    }

    if (paymentMethod === 'cashApp' && !cashAppUsername.length) {
        newForm.cashAppUsernameError = 'Please enter username';
        newForm.error = true;
    }

    newForm = {...newForm, ...validateRequiredFields(newForm, requiredFields)};

    newForm.error = hasError(newForm);

    return newForm;
};

export const validateAccountForm = (form: Partial<RegistrationForm>): Partial<RegistrationForm> => {
    const {isPromotionsTextChecked, isTermsAndConditionsChecked} = form;

    let newForm = {...form};
    // The account form combines several other forms, so we reuse their validation logic.
    // Make sure we're passing the latest validated form data.
    const {error: userInfoError, ...userInfoFields} = validateUserInfo(form);
    const {error: paymentDetailsError, ...paymentDetailsFields} = validatePaymentDetails({...form, ...userInfoFields});

    newForm = {
        ...newForm,
        ...userInfoFields,
        ...paymentDetailsFields,
        error: userInfoError || paymentDetailsError || false
    };

    if (!isTermsAndConditionsChecked) {
        newForm.isTermsAndConditionsCheckedError = 'You must accept the terms and conditions to use Calitopia';
    }

    const isValidPhoneNumber = isValidNumberForRegion(newForm.phoneNumber, 'US');

    if (isPromotionsTextChecked && !isValidPhoneNumber) {
        newForm.phoneNumberError = 'Please enter a valid phone number';
    }

    // Flag the general error key if there are any field error messages
    newForm.error = hasError(newForm);

    return newForm;
};

export const validateStepRegistrationForm = (form: Partial<StepRegistrationForm>): Partial<StepRegistrationForm> => {
    const {isTermsAndConditionsChecked, email, firstName, lastName, password, otp} = form;
    let newForm = {...form};
    let requiredFields = ['email', 'firstName', 'lastName', 'phoneNumber', 'otp'];
    if (!validateEmail(email)) {
        newForm.emailError = 'You must use a valid email';
    }
    if (!firstName) {
        newForm.firstNameError = 'Please enter a valid first name';
    }
    if (!lastName) {
        newForm.lastNameError = 'Please enter a valid last name';
    }
    if (!otp) {
        newForm.otpError = "Please enter otp."
    }
    if (!otpRegex.test(otp)) {
        console.log('in,error')
        newForm.otpError = "Please enter valid otp."
    }
    // if (password && !validatePassword(password)) {
    //     newForm.passwordError = 'Password should be 8-20 characters and contains at least one uppercase letter, one lowercase letter, one number and one special character'
    // }
    newForm = {...newForm, ...validateRequiredFields(newForm, requiredFields)};
    newForm.error = hasError(newForm);
    newForm = {...newForm};
    if (!isTermsAndConditionsChecked) {
        newForm.isTermsAndConditionsCheckedError = 'You must accept the terms and conditions to use Calitopia';
    }
    const isValidPhoneNumber = isValidNumberForRegion(newForm.phoneNumber, 'US');
    if (!isValidPhoneNumber) {
        newForm.phoneNumberError = 'Please enter a valid phone number';
    }
    // Flag the general error key if there are any field error messages
    newForm.error = hasError(newForm);
    return newForm;
};

export const validateAddEditSurveyForm = (form: Partial<AddEditSurveyForm>): Partial<AddEditSurveyForm> => {
    let newForm = {...form};
    let requiredFields = ['surveyName', 'surveyId'];
    newForm = {...newForm, ...validateRequiredFields(newForm, requiredFields)};
    newForm.error = hasError(newForm);
    return newForm;
};

export const validateAddEditLinkSurveyForm = (form: Partial<AddEditLinkSurveyForm>): Partial<AddEditLinkSurveyForm> => {
    let newForm = {...form};
    let requiredFields = ['brand', 'survey'];
    newForm = {...newForm, ...validateRequiredFields(newForm, requiredFields)};
    if (newForm.survey.length > 0){
        newForm.surveyError = '';
    }
    newForm = {...newForm};
    newForm.error = hasError(newForm);
    return newForm;
};

export const validateLoginForm = (form: Partial<LoginForm>): LoginForm => {
    const {phoneNumber, otp} = form;
    console.log(phoneNumber, otp)
    const newForm = {...form};

    if (!phoneNumber) {
        newForm.phoneNumberError = 'Phone number is required';
        newForm.error = true;
    }

    if (phoneNumber && !validatePhone(phoneNumber)) {
        newForm.phoneNumberError = 'Please enter a valid phone number.';
        newForm.error = true;
    }

    if (!otp) {
        newForm.otpError = 'Please enter otp.';
        newForm.error = true;
    }
    newForm.error = hasError(newForm);

    return newForm;
};
