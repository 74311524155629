// External Dependencies
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams, useLocation } from 'react-router-dom';

// Internal Dependencies
import { AppContext } from 'AppContext';
import ErrorState from '../ErrorState';
import { PageContainer } from 'shared/styled-components';
import RebateList from '../RebateList';
import { getRebateClaiming } from 'shared/api';
import { SessionContext } from 'session/SessionContext';
import firebase from 'firebase';

// Styles
const NewSubmissionMessage = styled.div`
  & > p {
    text-align: left;
  }
`;

const StatusCard = styled(Card)`
  margin-top: 1em;
  padding: 1em;
  text-align: left;

  & * h2 {
    color: #454545;
    font-family: Raleway;
    font-size: 2em;
    font-weight: 400;
    margin-bottom .5em;
    text-align: center;
  }
`;

const Title = styled.div`
  font-style: italic;
  margin-bottom: 0.5em;
  text-align: left;
`;

// Prepends @ if missing
const formatVenmo = (s) => (s[0] === '@' ? s : '@' + s);

// Component Definition
const SingleRebateStatus = () => {
  const {
    appBar: { showProfileModal }
  } = useContext(AppContext);
  const { user, userId } = useContext(SessionContext);
  const { state } = useLocation();
  const { id } = useParams<{ id: string }>();

  // Local State
  const [error, setError] = useState<string>('');
  const [isGetting, setIsGetting] = useState<boolean>(true);
  const [rebateClaimingData, setRebateClaimingData] = useState<any>(null);
  const [selectedRebates, setSelectedRebates] = useState<Array<any>>([]);

  const defaultError = 'There was a problem getting data for your rebate. Please try again later.';

  // Determines whether the user just arrived from the Rebate form
  // @ts-ignore
  const isNew = state !== undefined && state.isNew;

  const getRebatesBySlug = async (brandName) => {
    const result = await firebase
      .firestore()
      .collection('Brand')
      .where('slugs', 'array-contains-any', [brandName, brandName.toLowerCase()])
      .get();
    if (result.docs?.[0]?.data?.().rebates?.length > 0) {
      return result.docs?.[0]?.data?.().rebates;
    } else {
      return [];
    }
  };

  useEffect(() => {
    getRebateClaiming(id)
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          setRebateClaimingData(data);

          if (data?.userId !== userId) {
            throw new Error('Sorry. You cannot view this rebate.');
          }

          return [data?.brandName, data?.rebateIDs];
        } else {
          throw new Error('Rebate not found');
        }
      })
      .then(async ([brandName, ids]) =>
        (await getRebatesBySlug(brandName)).filter((result) => ids.includes(result.title))
      )
      .then((items) => setSelectedRebates(items))
      .then(() => setError(''))
      .catch((error) => setError(error.message || defaultError))
      .finally(() => setIsGetting(false));
  }, [user, id, userId]);

  if (!user) {
    return (
      <PageContainer>
        <p>You must be logged in to view rebates. Click the "Log In" button above.</p>
      </PageContainer>
    );
  }

  if (isGetting) {
    return (
      <PageContainer>
        <CircularProgress style={{ marginTop: 20 }} />
        <p>Searching for your rebate</p>
      </PageContainer>
    );
  }

  if (Boolean(error)) {
    return (
      <PageContainer>
        <ErrorState>
          <span>{error}</span>
        </ErrorState>
      </PageContainer>
    );
  }

  if (!rebateClaimingData) {
    return (
      <div>
        <p>{defaultError}</p>
      </div>
    );
  }

  const { brandName, dateCreated, status, venmoUsername } = rebateClaimingData;

  const prettyDate = new Date(dateCreated).toLocaleDateString();

  const deliveryMethod = Boolean(venmoUsername) ? (
    <span>
      via Venmo to <strong>{formatVenmo(venmoUsername)}</strong>
    </span>
  ) : (
    <span>
      by mail to the address we have on file: <strong></strong>
    </span>
  );

  const profileLink = (
    <a aria-roledescription="opens profile dialog" href="#" onClick={showProfileModal}>
      profile
    </a>
  );
  return (
    // @ts-ignore
    <PageContainer>
      <Title style={{ marginTop: 20 }}>
        {brandName || ''} Rebate - Submitted {prettyDate}
      </Title>
      <RebateList rebateData={selectedRebates} selectedRebates={[]} />
      <StatusCard>
        {isNew ? (
          <NewSubmissionMessage>
            <h2>Thanks!</h2>
            <p>
              Remember to confirm your email so that we can send you your rebate. If you need to{' '}
              change your information click your{' '}
              <a href="#" onClick={showProfileModal}>
                profile
              </a>
              . After your email is confirmed and we review your receipt and profile, we will send
              you your rebate by the method that you selected. You can login for future rebates or
              to check your rebates status. Please let us know if you have any questions.
              <br />
              <a href="mailto:help@calitopia.co">help@calitopia.co</a>
            </p>
          </NewSubmissionMessage>
        ) : (
          <div>
            <span>
              Current Rebate Status: <strong>{status}</strong>
            </span>
            <p>
              Remember to confirm your email so that we can send you your rebate. If you need to
              change your information click your {profileLink}. Please let us know if you have any
              questions.
              <br />
              <a href="mailto:help@calitopia.co">help@calitopia.co</a>
            </p>
          </div>
        )}
        <p>Your rebate will be sent {deliveryMethod}.</p>
        {/* <p>Want more deals? Download the Calitopia App!</p> */}
      </StatusCard>
    </PageContainer>
  );
};

export default SingleRebateStatus;
