import React, { useState } from "react";

const CarouselButton = ({ image, turn }) => {
  const [pointer, setPointer] = useState("");
  return (
    <div
      style={{
        height: "45px",
        width: "45px",
        borderRadius: "50%",
        zIndex: 3,
        cursor: pointer,
      }}
      onClick={turn}
      onMouseOver={() => {
        setPointer("pointer");
      }}
      onMouseOut={() => {
        setPointer("");
      }}
    >
      <img
        style={{ height: "100%", width: "100%" }}
        src={image}
        alt={"Unable to load"}
      ></img>
    </div>
  );
};

export default CarouselButton;
