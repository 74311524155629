import { Cancel, Tag } from '@material-ui/icons';
import { FormControl, TextField, Typography, Box } from '@material-ui/core';
// import { Stack } from '@material-ui';

import { useRef, useState } from 'react';

const Tags = ({ data, handleDelete }) => {
  return (
    <Box
      sx={{
        background: '#bbb',
        height: '100%',
        display: 'flex',
        padding: '0.4rem',
        margin: '10px 5px 0px 0px',
        justifyContent: 'center',
        alignContent: 'center',
        color: '#ffffff',
        gap: 10,
        flexDirection: 'row'
      }}
    >
      <Typography>{data}</Typography>
      <Cancel
        style={{ cursor: 'pointer' }}
        onClick={() => {
          handleDelete(data);
        }}
      />
    </Box>
  );
};

export default function InputTags(props) {
  const { setFieldValue, rebateId, questionId, values, errors } = props;
  const [tags, setTags] = useState([]);
  const tagRef = useRef();
  const [value, setValue] = useState('');

  const handleDelete = (value) => {
    const newtags = values?.rebates?.[rebateId]?.surveyQuestions?.[questionId]?.options?.filter(
      (val) => val !== value
    );
    setFieldValue(`rebates[${rebateId}].surveyQuestions[${questionId}].options`, newtags);
  };
  const handleOnSubmit = (e) => {
    setValue(e.target.value);
    if (e.keyCode === 13 && e.target.value.trim().length > 0) {
      setFieldValue(`rebates[${rebateId}].surveyQuestions[${questionId}].options`, [
        ...values?.rebates?.[rebateId]?.surveyQuestions?.[questionId]?.options,
        tagRef.current.value.trim()
      ]);
      tagRef.current.value = '';
      setValue('');
    }
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <TextField
        inputRef={tagRef}
        fullWidth
        label="Options"
        value={value}
        variant="outlined"
        size="small"
        style={{ margin: '1rem 0' }}
        margin="dense"
        type="text"
        onKeyDown={handleOnSubmit}
        onChange={handleOnSubmit}
        placeholder={
          values?.rebates?.[rebateId]?.surveyQuestions?.[questionId]?.options?.length || 0 < 5
            ? 'Enter Option'
            : ''
        }
        InputProps={{
          startAdornment:
            values?.rebates?.[rebateId]?.surveyQuestions?.[questionId]?.options?.length > 0
              ? values?.rebates?.[rebateId]?.surveyQuestions?.[questionId]?.options.map(
                  (data, index) => {
                    return <Tags data={data} handleDelete={handleDelete} key={index} />;
                  }
                )
              : ''
        }}
        helperText={errors.rebates?.[rebateId]?.surveyQuestions?.[questionId]?.options || ''}
        error={Boolean(errors.rebates?.[rebateId]?.surveyQuestions?.[questionId]?.options)}
      />
    </Box>
  );
}
