// External Dependencies
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

/**
 * Wraps a standard MUI button to prevent it from being clicked multiple times within the same submission.
 * @param {Object} children - A react node or string representing the button's label. 
 * @param {boolean} isSubmitting - When true replaces the Button with a loading spinner.
 */
const SafeButton = ({ children, isSubmitting, ...props }) =>
  isSubmitting ? (
    <div style={{ width: '100%', textAlign: 'center',marginTop:10 }}>
      <CircularProgress />
    </div>
  ) : (
    <Button {...props}>{children}</Button>
  );

export default SafeButton;
